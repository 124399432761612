* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    --theme: #413c9f;
}

.formItem {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    padding: 20px;
    /* margin: 20px; */

}

#jobTitle,
#designation-select,
#location-select,
#currency-select {
    width: 400px;
    padding-left: 20px;
    height: 35px;
    border-radius: 10px;

}

hr {
    position: relative;
    /* top: 10px; */
    border: none;
    height: 0.1px;
    background-color: rgb(181, 180, 180);
    box-shadow: .5px .5px 3px #d8d7d7;
    /* margin-bottom: 50px; */

}

h5 {
    padding: 5px;
}

#checkBox {
    border: 1px dashed white;
    cursor: pointer;
    box-shadow: -1px -1px 5px rgb(181, 180, 180);
    border-radius: 10px;
    padding-right: 5px;
    width: 180px;
}

#submit-btn {
    display: inline-block;
    background-color: #0e3f6c;
    color: white;
    letter-spacing: 2px;
    left: 80%;
    width: 120px;
    transition: all.2s ease-out;
}

#submit-btn:hover {
    background-color: rgb(39, 39, 169);
    transition: all .3s ease-in;
    transform: scaleY(1.1);

}