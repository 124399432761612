* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

h5 {
    font-size: 1.8rem;
    font-weight: 600;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    text-align: center;
}

p {
    font-size: 1.2rem;
}

#upload-btn {
    width: 250px;
    height: 50px;
    letter-spacing: 2px;
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
}

.file-det {
    margin-top: 2rem;
}