@import url(https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap);
body{margin:0;font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen";-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}code{font-family:source-code-pro,Menlo,Monaco,Consolas,"Courier New",monospace}svg{cursor:pointer}.MuiAutocomplete-inputRoot{padding:0px !important}.filter-header{width:95%;margin:0 auto}.filter-header .MuiAutocomplete-inputRoot[class*=MuiOutlinedInput-root]{padding:0}.input .MuiSelect-root{padding:18.5px 14px !important}.input input{color:#000 !important;padding:18.5px 14px !important}
.notification-success {
    background-color: #924a91;

}

.notification-success::before {
    content: "";
  }
.login-container{height:100vh;background-size:100%;width:100%;background-repeat:no-repeat;justify-content:center;display:flex;align-items:center;background:#0e3f6c}.mainHeading{font-size:2rem;margin-left:5rem;width:100%;color:#010101;font-weight:300;font-weight:500;color:#162e70}.login-inner-cont{display:flex;align-items:center}



.profile-button:hover{
    background-color: #979797;
}



.main-file-upload{width:100%;display:flex;flex-direction:column;justify-content:center;align-items:center;padding:40px 0}.main-file-upload .file-uploader{display:flex;align-items:center}
* {
  box-sizing: border-box;
  --theme: #162e70;
  --lightTheme: #496cce;

}

.leftContentHeader {
  width: auto;
  margin: 10px 0;
}

.leftContentHeader h2 {
  font-size: 2.5rem;
  color: #2c3e50;
}

.contentHeader {
  width: 100%;
  padding: 0px 10px 40px 10px;
}

.homeHeading {
  font-size: 2rem;
  margin-left: 0;
  font-weight: 300;
  font-weight: 500;
  color: #162e70;
}

.mainPara {
  font-size: 20px;
  font-weight: 400;
  color: #162e70;
  margin-top: 15px;
}

.card {
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: 280px;
  width: auto;
  margin: 5px;
}

.card>ul>li {
  word-wrap: break-word;
}

.card-head {
  font-size: 1.2rem;
}

.profile-card {
  height: 560px;
}

.card-main {
  display: flex;

  align-items: center;
  margin-left: 50px;
}

.cards-container {
  width: auto;
}

.card {
  margin: 10px !important;
}




.showmore {
  background-color: var(--theme);
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  line-height: 20px;
  list-style: none;
  margin: 0;
  outline: none;
  padding: 10px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color 100ms;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  box-shadow: 0 8px 10px 0px rgba(16, 16, 17, 0.5);
}

.showmore:hover {
  color: black;
  border-color: currentColor;
  background-color: #F082AC;
  box-shadow: 0 8px 10px 0px rgba(16, 16, 17, 0.5);
}

.cardsAnalytics {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  font-family: monospace;
  flex-wrap: wrap;
}

.analytics-page {
  font-family: 'Arial', sans-serif;
  margin: 20px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.analytics-boxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.analytics-box {
  width: 100px;
  flex: 1 1;
  background-color: #ecf0f1;
  color: #333;
  padding: 20px;
  margin: 10px;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;
}

h4 {
  /* color: #2c3e50; */
  font-weight: bold;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin-bottom: 5px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

th,
td {
  /* border: 1px solid #bdc3c7; */
  padding: 8px;
  text-align: left;
}

th {
  background-color: #3498db;
  color: #ecf0f1;
}

@media screen and (max-width: 1200px) {
  .analytics-box {
    flex: 0 0 48%;
  }
}

@media screen and (max-width: 768px) {
  .analytics-box {
    flex: 0 0 100%;
  }
}

.eye-icon {
  cursor: pointer;
  margin-left: 5px;
  font-size: 1.8rem;
  font-weight: bold;
}

.eye-icon:hover {
  cursor: pointer;
}

.analytics-boxesForPersonalinfo {
  flex-wrap: wrap;
  justify-content: space-between;
}

.analytics-boxForPersonalInfo {
  display: flex;
  background-color: #ecf0f1;
  color: #333;
  padding: 20px;
  margin: 10px;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.left-content {
  margin-right: 20px;
  /* color: #C27BA0; */
  color: var(--theme);
}

.left-content svg {
  width: 8.5rem;
  height: 6.5rem;
}

.right-content {
  flex-grow: 1;
}

.right-content span {
  font-weight: bold;
  font-size: 18px;
}

.right-content p {
  font-size: 14px;
  margin: 8px 0;
}

@media (max-width: 768px) {
  .right-content span {
    font-size: 16px;
  }

  .right-content p {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .analytics-boxForPersonalInfo {
    flex-direction: column;
  }

  .analytics-boxesForPersonalinfo {
    justify-content: flex-start;
  }

  .left-content svg {
    width: 4rem;
    height: 4rem;
  }
}

.custom-tooltip {
  position: relative;
  display: inline-block;
}

.custom-tooltip .tooltip-content {
  visibility: hidden;
  background-color: #333;
  color: #fff;
  font-weight: bolder;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.custom-tooltip:hover .tooltip-content {
  visibility: visible;
  opacity: 1;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
}

.mainHeading {
  font-weight: bold;
  color: var(--theme);
  font-size: 2.5rem;
  padding-left: 1.5rem;
  width: 100%;

}

/* profile info section */

.user-sec {
  margin-top: 2rem;
  margin-left: 20px !important;
  margin-right: 15px !important;
  padding-bottom: 10px;
  /* width: 94%; */
}

.user-Section {
  background-color: hsl(192, 15%, 94%);
  box-shadow: 2px 5px 15px rgb(145, 137, 137);
  height: 70px;
  border-radius: 10px;
  /* margin-left: 10px; */
}

.inner-cont {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  padding-left: 10px;
}

.inner-cont>div {
  width: 200px;
  text-align: center;
  padding-top: 20px;
}

.inner-cont>div>button::after {
  content: "";
  height: 3px;
  background-color: var(--theme) !important;
  display: block;
  width: 0;
  transition: all .1s ease;
}

.inner-cont button:hover::after {
  width: 150px;
  transition: all .2s ease;
}


.inner-cont button {
  border: 0;
  outline: 0;
  text-align: center;
  letter-spacing: 2px;
  font-size: 1.3rem;
  font-family: inherit;
  background-color: transparent;
  color: #000;
}

/* User Details section */
.ud-container {
  display: flex;
  width: 94%;
  height: 550px;
  margin: 3rem;
  background-color: #ecf0f1;
  border-radius: 10px;
  box-shadow: 5px 5px 10px rgb(176, 172, 172);
  padding: 20px 25px;
  overflow-y: scroll;
}

.ud-left-outer {
  margin-left: 3rem;
}

.ud-left-inner>div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 25px 10px;
  width: 100%;
  cursor: pointer;
}

.ud-left-inner>div:hover {
  /* background-color: rgba(220, 217, 217, 0.651); */
  color: var(--lightTheme);
}

#ud-left-icon {
  margin-bottom: 0;
  margin-right: 8px;
  width: 30px;
  height: 30px;
  fill: var(--theme);
}

.ud-left-inner>div>h6 {
  font-weight: 700;
  padding-top: 10px;
  padding-left: 10px;
  font-size: 1.4rem;
  color: var(--theme);
  font-weight: 700 !important;
}

.ud-left-inner>div>:hover {
  color: rgb(93, 136, 231) !important;
  fill: var(--lightTheme);
  transition: all .2s ease-in;
  text-decoration: underline;
  text-shadow: 1px 1px 5px rgba(93, 137, 231, 0.404) !important;
}

.middle {
  padding-top: 2rem;
  /* height: 100%; */
}

.scroll-vertical {
  content: " ";
  height: 410px;
  width: 3px;
  background-color: rgb(193, 192, 192);
  margin-left: 3rem;
  margin-right: 2rem;
}

/* right section */
.ud-right {
  height: 100%;
  width: 100%;
  padding-top: 1rem;
}

.ud-right-cont {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-right: 2rem;
}



.ud-right-inner {
  display: flex;
  justify-content: space-between;
  width: auto;
}

/* Edit button for form */
.form-container {
  max-width: inherit;
}


.aboutSec>div>h6 {
  margin-bottom: 10px;
  font-size: 1.05rem;
  color: var(--theme);
  font-weight: 600;
  font-family: Georgia, 'Times New Roman', Times, serif;
  -webkit-text-decoration: dashed;
          text-decoration: dashed;
}

.dynamic-form-values,
.static-form-values {
  margin-bottom: 30px;
}

.dynamic-form-values h6,
.static-form-values>h6 {
  margin-bottom: 10px;
  font-size: 1.05rem;
  color: var(--theme);
  font-weight: 600;
  font-family: Georgia, 'Times New Roman', Times, serif;
  -webkit-text-decoration: dashed;
          text-decoration: dashed;
}

.aboutSec>div>p {
  width: 500px;
  height: 40px;
  padding: 8px;
  border: 1px solid #fff;
  border-radius: 10px;
  box-sizing: border-box;
  font-size: 1rem;
  letter-spacing: 2px;
  text-align: center;
  border-radius: 20px;
  box-shadow: inset 0px 1px 10px rgba(149, 146, 146);

}

.aboutSec>div {
  margin: 20px;
}

.aboutSec>div>input {
  width: 500px;
  padding: 8px;
  border: 0;
  outline: 0;
  background-color: transparent;
  box-sizing: border-box;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 1px;
  border-radius: 10px;
  box-shadow: 1px 1px 10px rgba(131, 154, 195, 0.82);
  padding-left: 40px;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.aboutSec>div>input:focus {
  border: 1px solid blue;
  color: var(--lightTheme);
  font-size: 1.2rem;
  font-weight: 500;
  transition: all .2s ease-in-out;
}

.aboutSec>div>input::placeholder {
  color: #848383;
  font-size: .9rem;
  font-weight: 400;
}

.dynamiv-form-values input:focus {
  outline: none;
  border-color: #007bff;
}

.save-btn button {
  background-color: var(--theme);
  width: 100px;
  color: #FFFFFF;
  border-radius: 10px;
  transition: all .2s ease-in-out;
  margin: 10px;
  padding: 5px;

}

.save-btn button:hover {
  background-color: blue;
}

.edit-btn {
  border: 2px solid #1b1618;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  padding: 5px;
  position: -webkit-sticky;
  position: sticky;
  transition: all .5s ease-in-out;
}

.edit-btn:hover {
  border: 2px solid rgb(61, 15, 224);
  color: var(--theme);
  transition: all .5s ease-in-out;
  transform: rotate3d(0, 0, 1, 360deg);
  ;
}

.user-biodata {
  margin-left: 1.5rem;
}

.work-profile>div>h6,
.user-bio>div>h6,
.user-dob>div>h6 {
  margin-bottom: 10px;
  font-size: 1.05rem;
  color: var(--theme);
  font-weight: 600;
  font-family: Georgia, 'Times New Roman', Times, serif;
  -webkit-text-decoration: dashed;
          text-decoration: dashed;
  margin-bottom: 1rem;
}



.work-profile>div>p,
.user-bio>div>p,
.user-dob>div>p {
  font-size: 1.3rem;
  font-weight: bold;
  font-family: 'Playfair Display', serif;
  letter-spacing: 3px;
  padding-left: 10px;
}

.rep-location>h3,
.add-details>h3,
.cont-dtl>h3,
.dep-details>h3 {
  font-family: 'Times New Roman', Times, serif;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 2rem;
  color: var(--theme);
}



.add-head,
.cont-head,
.dep-head h5 {
  margin-bottom: 2rem;
  color: var(--theme);
  font-family: 'Times New Roman', Times, serif;
  letter-spacing: 2px;
}

.cont-div,
.add-div,
.dep-div {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  /* margin-top: 2rem; */
  width: 100%;
}

.add-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  margin-left: 20px;
  width: 100px;
  height: 40px;
  font-size: 1.5rem;
  color: white;
  background-color: var(--theme);
  cursor: pointer;
  border: none;
  border-radius: 5px;
  box-shadow: 5px 3px 10px rgb(99, 121, 193);
  font-family: 'Times New Roman', Times, serif;
  transition: all .25s ease-in-out;
}

.add-btn:hover,
.cont-btn:hover,
.dep-btn:hover {
  height: 44px;
  width: 105px;
  transition: all .25s ease-in-out;
}

.add-img,
.cont-img,
.dep-img {
  align-self: center;
  min-width: 200px;
  max-width: 400px;
  margin-left: 100px;
  padding-right: 1rem;
}

.dep-details {
  width: 250px;
}

.rep-img {
  margin-left: 10px;
  width: 350px;

}

.cont-img {
  width: 310px;
}

.rep-city-cont,
.user-curr-exp {
  margin-top: 2rem;
  width: 250px;

}

.rep-city>div>h6,
.user-exp>div>h6 {
  color: #333;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  line-break: auto;
  font-family: 'Times New Roman', Times, serif;
  margin-bottom: 1rem;
}

.loc-btn {
  height: 20px;
  width: 60px;
  font-size: .9rem;
  padding: 0;
  margin-left: 0;
  background-color: grey;
  box-shadow: none;
  transition: all .25s ease-in-out;
}

.loc-btn:hover {
  height: 22px;
  width: 65px;
  background-color: var(--theme);
  box-shadow: 5px 3px 10px var(--lightTheme);
  transition: all .25s ease-in-out;
}


.input-type-file {
  border: 1px dashed var(--theme);
  padding: 10px;
  margin: 2rem;
}

.contEditSec>div {
  margin: 10px;
}

.contEditSec>div>h5,
.editDependents>div>h5 {
  font-size: 1rem;
  color: var(--theme);
  display: flex;
  margin: 5px 0;
}


/* For small screens */

@media only screen and (min-width: 250px) and (max-width:500px) {

  .card-main {
    display: block;
    margin-left: 0 !important;
  }

  .cards-container {
    display: block;
    width: auto !important;
    margin-left: auto;
  }

  .card-comp {
    margin: 0 !important;
  }

  .user-card {
    width: auto !important;
  }

  .left-card-cont {
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .userDetails {
    padding: 0 !important;
    height: 234px;
  }

  .user-item {
    width: auto !important;
  }

  .profile-card {
    height: 100%;
  }

  .card {
    flex: 1 1 100%;
    margin: 5px 0 !important;
  }

  .inner-cont button {
    font-size: .9rem !important;
  }

  .scroll-vertical {
    margin-left: 20px !important;
  }


  .user-biodata {
    margin-left: 0 !important;
  }

  .user-sec {
    margin-left: 0 !important;
    width: auto !important;
  }

  .user-Section {
    margin-left: 0 !important;

  }

  .ud-left-outer {
    margin-left: 10px !important;

  }

  .user-Section button {
    font-size: .6rem;
  }

  .ud-container {
    padding: 10px 5px !important;
    width: 90% !important;
    margin: 1rem !important;
  }

  .aboutSec>div>p {
    width: 200px !important;
    letter-spacing: 0;
  }

  .cont-div,
  .add-div,
  .dep-div {
    display: inline-block !important;
    margin-top: 2rem;
    width: auto !important;
  }

  .add-img,
  .dep-img,
  .cont-img {
    width: 210px !important;
    height: 200px !important;
    margin-left: 10px;
    padding-right: 10px;
  }

  .doc-img {
    display: block;
    padding-top: 0 !important;
    width: 200px;
    height: 160px;
  }

  .exp-img {
    display: none;
  }
}


@media only screen and (min-width: 500px) and (max-width:700px) {

  .card-main {
    display: block;
    margin-left: 0 !important;
  }

  .cards-container {
    display: block;
    width: auto !important;
    margin-left: auto;
  }

  .user-card {
    width: auto !important;
  }

  .profile-card {
    justify-content: space-evenly;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  .card {
    flex: 1 1 100%;
    margin: 10px !important;
  }

  .card svg {
    width: 15px !important;
    height: 15px !important
  }

  .inner-cont button {
    font-size: 1.1rem !important;
  }

  .scroll-vertical {
    margin-left: 20px !important;
  }

  .user-biodata {
    margin-left: 0 !important;
  }

  .user-Section {
    margin-left: 0 !important;
  }

  .ud-container {
    padding: 10px 5px !important;
    width: 98% !important;
    margin: 3rem 1rem;
  }

  .ud-left-outer {
    margin-left: 10px !important;
  }

  .aboutSec>div>p {
    width: 220px !important;
  }

  .cont-div,
  .add-div,
  .dep-div {
    display: inline-block !important;
    margin-top: 2rem;
    width: auto !important;
  }

  .add-img,
  .dep-img,
  .cont-img {
    width: 260px !important;
    height: 240px !important;
    margin-left: 10px;
    padding-right: 10px;
  }

  /* .add-exp {
    display: none;
  } */

  .doc-img,
  .exp-img {
    display: block;
    padding-top: 0 !important;
    width: 200px;
    height: 160px;
  }

  .add-exp {
    display: none !important;
  }
}

/* For medium screens */
@media only screen and (min-width: 700px) and (max-width: 1000px) {

  .cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: 20px;
  }

  .user-card {
    width: 220px !important;
  }

  .card {
    flex: 1 1 40%;
    margin: 10px !important;
  }

  .card svg {
    width: 15px !important;
    height: 15px !important
  }

  .ud-left-outer {

    margin-left: 0 !important;
  }

  .scroll-vertical {
    margin-left: 20px !important;
  }

  .user-biodata {
    margin-left: 0 !important;
  }


}

/* For large screens */
@media only screen and (min-width: 1000px) {
  .cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .card {
    flex: 1 1 40%;
    margin: 10px 0;
  }


}

.centerItem{
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.ExpTitle{
  color: #413c9f !important;
  font-size: xx-large !important;
  font-weight: 700;
}
.companyName{
  font-size: 25px !important;
  font-weight: 700 !important;
}
.validationError{
  color: #f44336;
  font-size:0.75rem;
  font-weight:400;
  line-height:1.66;
  margin-top:3px;
  margin-left:14px;
  margin-right:14px;
  letter-spacing:0.03333em;
}

.uploadField{
  width: 100%;
  margin: 0;
  padding: 7px !important;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 5px;
  height: 40px !important;
  cursor: pointer;
}
       
.uploadInput input{
  color: black !important;
  padding: 8.5px 14px !important;
}
       
.uploadInput label{
  transform: translate(14px, -6px) scale(0.75) !important;
  padding: 0 5px;
  background: white !important;
}

.editButton{
  border: 1px solid rgba(0, 0, 0, 0.23);
  background: none;
  padding: 7px;
  border-radius: 5px;
}

.disableDate :disabled{
  background-color:  #EBEBE4;
}
.disableDate input:disabled{
  color: gray !important;
}

.showCredential{
  margin-top: 12px;
  padding: 10px;
  border-radius: 20px;
  font-size: 15px;
  font-weight: 500;
}
.input .MuiSelect-root{padding:18.5px 14px !important}.input input{color:#000 !important;padding:18.5px 14px !important;height:1px}.input label{transform:translate(14px, -6px) scale(0.75) !important;padding:0 5px;background:#fff !important}
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

h5 {
    font-size: 1.8rem;
    font-weight: 600;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    text-align: center;
}

p {
    font-size: 1.2rem;
}

#upload-btn {
    width: 250px;
    height: 50px;
    letter-spacing: 2px;
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
}

.file-det {
    margin-top: 2rem;
}
.input .MuiSelect-root{padding:18.5px 14px !important}.input input{color:#000 !important;padding:18.5px 14px !important}.input label{transform:translate(14px, -6px) scale(0.75) !important;padding:0 5px;background:#fff !important}.MuiOutlinedInput-input{padding:none !important}.MuiOutlinedInput-input{padding:3px 11px !important}.MuiOutlinedInput-input .inline{display:flex !important}.MuiAutocomplete-inputRoot[class*=MuiOutlinedInput-root]{padding:0 !important}
.cardsAnalytics {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    font-family: monospace;
  }
  
  .cardsAnalytics > div {
    box-shadow: 0 8px 10px 0px rgba(16, 16, 17, 0.5);
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);
    border-radius: 4px;
    background-color: #d0e0e2;
    border: 4px solid rgba(255, 255, 255, 0.18);
    width: 22%;
    display: flex;
    align-items: right;
    flex-direction: column;
    padding: 15px;
  }
  
  .card-data {
    display: block;
    margin-bottom: 5px; 
  }
  
  .right-align {
    float: right;
  }
  
  .cardsAnalytics > div > h3 {
    text-align: center;
    font-size: 0.7vw;
    color: white;
  }
  
  .cardsAnalytics > div > h2 {
    text-align: center;
    font-size: 2vw;
    color: white;
  }
  .attendance-regularize{
    border: 1px solid blue;
  }
  .leave-apply-btn{
    background-color: #EA4C89;
    border-radius: 8px;
    border-style: none;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    height: 40px;
    line-height: 20px;
    list-style: none;
    margin: 0;
    outline: none;
    padding: 10px 16px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: color 100ms;
    vertical-align: baseline;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    box-shadow: 0 8px 10px 0px rgba(16, 16, 17, 0.5);
  }
  .leave-apply-btn:hover {
    color: black;
    border-color: currentColor;
    background-color: #F082AC;
    box-shadow: 0 8px 10px 0px rgba(16, 16, 17, 0.5);
}

form {
  display: flex;
  flex-direction: column;
  align-items: right;
  margin-top: 50px;
}

label {
  margin-bottom: 10px;
}

/* input[type="date"],
input[type="time"],
input[type="text"] {
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 300px;
} */

/* button {
  padding: 10px 20px;
  border-radius: 2px;
  border: none;
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
  margin-top: 20px;

} */



.button-style {
  margin: 20px;
  padding: 5px 35px;
  border: none;
  border-radius: 5px;
  /* background-color: blue; */
  background-color:rgb(103, 37, 103);
  color: white;
  font-size: 16px;
  cursor: pointer;
  
}
.button-style:hover {
  
  background-color:rgb(105, 63, 105); 
}



.button1 {
 
  /* background-color: #4CAF50;  */
  border: none;
  background-color: #ffffff; 
  /* color: white; */
  color: rgb(144, 155, 204);
  text-align: center;
  display: inline-block;
  font-size: 20px;
  margin: 4px 2px;
  
  cursor: pointer;
  border-radius: 5px;
  border: 2px solid rgb(144, 155, 204);
  padding: 10px 24px;
  border-radius: 10px;
  width: 100%; /* Make the buttons full-width */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); 
}

@media screen and (min-width: 600px) {
  .button1 {
    width: 30%; /* Change the width of the buttons for larger screens */
  }
}
.button1:hover {
  background-color: #c7dfc7;
  
  

}
.use1{
  padding:auto;
  display: flex;
  border: none;
  background-color: #ffffff; 
  /* color: white; */
  color: rgb(144, 155, 204);
  text-align: center;
  display: inline-block;
  font-size: 20px;
  margin: 30px 2px;
  
  cursor: pointer;
  border-radius: 5px;
  
  padding: 10px 34px;
  border-radius: 10px;
  width: 100%; /* Make the buttons full-width */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); 
}

.use3{
  display: flex;
  padding:auto;
  border: none;
  background-color: #ffffff; 
  /* color: white; */
  color: rgb(144, 155, 204);
  text-align: center;
  display: inline-block;
  font-size: 20px;
  margin: 4px 2px;
  
  cursor: pointer;
  
  width: 100%; /* Make the buttons full-width */
 
}






  .sidebar {
     background-color: white;  
    color: black;
    padding: 20px;
    width: 20%;
    box-sizing: border-box;
  }
  
  .right-content {
    flex: 1 1;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .content-item {
    cursor: pointer;
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .content-item:hover {
    background-color: #555;
  }
  
  .content-details {
    display: none;
  }
  
  .content-details.active {
    display: block;
  }
  
.MuiPagination-ul{margin:0;display:flex;padding:0;flex-wrap:inherit;list-style:none;align-items:center}.data-table svg{cursor:pointer}.data-table .MuiTableCell-root{padding:4px 4px !important}.data-table th,.data-table td{font-size:13px !important;min-width:8rem !important}
.hello {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background-color: #FBF9F1;
    border: 20px;
    border-radius: 10px;
    padding: 50px; 
    margin:  auto;
    color: #0a0a0a;
    box-shadow: 1px 1px 10px 0px rgb(12, 12, 43);
    font-size: 20px;
  
    
  }
  .box {
    border-radius: 10px;
    margin-top: 1rem;
    width: 50%;
    height: 150px;
    padding: 10px 10px 10px 10px;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    
    box-shadow: 1px 1px 10px 0px red;
  }
  
    
  
  .medical{
  /* width: 300px; */
  padding: 1rem;
  border: 1px solid #E5E1DA;
  border-radius: 10px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 1rem;
  background-color: #E5E1DA;
  color:#0a0a0a;
  }

  .button {
    color: rgb(14, 63, 108);
  }
.attendhello {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: #FBF9F1;
  border: 20px;
  border-radius: 10px;
  padding: 50px; 
  margin:  auto;
  /* margin-left: 500px; */
  color: #0a0a0a;
  box-shadow: 1px 1px 10px 0px rgb(12, 12, 43);
  font-size: 20px;
  
}
.box {
  border-radius: 10px;
  margin-top: 1rem;
  width: 50%;
  height: 150px;
  padding: 10px 10px 10px 10px;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 10px 0px red;
}

.tex {
  padding: 1rem;
  border: 1px solid #E5E1DA;
  border-radius: 10px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 1rem;
  background-color: #e0e0e0;;
  color:rgba(0, 0, 0, 0.87);;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}

.but {
  padding: 0.5rem 5rem;
  /* background-color: #92C7CF; */
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin:auto

}

.but:hover {
  /* background-color: #aad9bc; */
  background-color:rgb(105, 63, 105);
  
}
.MuiPagination-ul{margin:0;display:flex;padding:0;flex-wrap:inherit;list-style:none;align-items:center}.data-table svg{cursor:pointer}.data-table .MuiTableCell-root{padding:4px 4px !important}.data-table th,.data-table td{font-size:13px !important;min-width:8rem !important}
.mainHeading {
    margin-top: 50px;
}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.taxHeading{
    width: 100%;
    text-align: center;
    color: rgb(14, 63, 108);
    text-shadow: 5px 5px 15px grey;
    font-size: 3rem;
}
.MuiPagination-ul{margin:0;display:flex;padding:0;flex-wrap:inherit;list-style:none;align-items:center}.data-table svg{cursor:pointer}.data-table .MuiTableCell-root{padding:4px 4px !important}.data-table th,.data-table td{font-size:13px !important;min-width:8rem !important}

  
  .cardsAppointment {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
  }
  
  .cardsAppointment > div {
    background: rgb(14, 63, 108);
  
    box-shadow: 0 8px 10px 0px rgba(16, 16, 17, 0.5);
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    width: 20%;
    margin: 20px 20px;
  
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  
    padding: 15px;
  }
  
  .cardsAppointment > div > h3 {
    text-align: center;
    font-size: 0.7vw;
    color: white;
  }
  
  .cardsAppointment > div > h2 {
    text-align: center;
    font-size: 2vw;
    color: white;
  }
  
  @media screen and (max-width: 720px) {
    .pccDashContainer {
      width: 100%;
      margin: auto;
      height: calc(100vh - 120px);
      overflow-y: scroll;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 20px;
    }
    .cardsAppointment > div {
      width: 30%;
    }
  
    .cardsAppointment > div > h3 {
      text-align: center;
      font-size: 2vw;
      color: white;
    }
  
    .cardsAppointment > div > h2 {
      text-align: center;
      font-size: 5vw;
      color: white;
    }
  
    .pccDashContainer > div > h3 {
      font-size: 5vw;
      margin: 30px 0px 20px 0px;
      color: rgba(66, 58, 58, 0.87);
    }
  }
  
  
a:hover {
    background-color: rgb(228, 228, 215);
  }

.button-17 {
    align-items: center;
    -webkit-appearance: none;
            appearance: none;
    background-color: #fff;
    border-radius: 15px;
    border-style: none;
    box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
    box-sizing: border-box;
    color: #3c4043;
    cursor: pointer;
    display: inline-flex;
    fill: currentcolor;
    font-family: "Google Sans",Roboto,Arial,sans-serif;
    font-size: 20px;
    font-weight: 500;
    height: 50px;
    justify-content: center;
    letter-spacing: .25px;
    line-height: normal;
    max-width: 100%;
    overflow: visible;
    padding: 2px 70px;
    position: relative;
    text-align: center;
    text-transform: none;
    transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1),opacity 15ms linear 30ms,transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: auto;
    will-change: transform,opacity;
    z-index: 0;
  }
  
  .button-17:hover {
    background: #F6F9FE;
    color: #174ea6;
  }
  
  .button-17:active {
    box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%), 0 8px 12px 6px rgb(60 64 67 / 15%);
    outline: none;
  }
  
  .button-17:focus {
    outline: none;
    border: 2px solid #4285f4;
  }
  
  .button-17:not(:disabled) {
    box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
  }
  
  .button-17:not(:disabled):hover {
    box-shadow: rgba(60, 64, 67, .3) 0 2px 3px 0, rgba(60, 64, 67, .15) 0 6px 10px 4px;
  }
  
  .button-17:not(:disabled):focus {
    box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
  }
  
  .button-17:not(:disabled):active {
    box-shadow: rgba(60, 64, 67, .3) 0 4px 4px 0, rgba(60, 64, 67, .15) 0 8px 12px 6px;
  }
  
  .button-17:disabled {
    box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
  }



  
.example_g {
    font-family: 'Poppins';
    width: 320px;
    height: 50px;
    border: none;
    outline: none;
    color: #494949;
    background: #ffffff;
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 4px;
    cursor: pointer;
    position: relative;
    z-index: 0;
    transition: 0.5s ease-in-out;
}

.example_g:before {
    content: '';
    background: linear-gradient(45deg, #FF0000, #FFF700, #FF7000, #FF00FE, #3FF4EB, #9E00FF, #00BEFF, #FF00DD, #FF0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
}

.example_g:active {
    color: #000
}

.example_g:active:after {
    background: transparent;
}

.example_g:hover {
    transform: scale(1.05);
}

.example_g:hover:before {
    opacity: 1;
}

.example_g:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #ffffff;
    left: 0;
    top: 0;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}

.employee{
  margin-left: 3rem;
  margin-top: 2rem;
  align-items: center;
  -webkit-appearance: none;
          appearance: none;
  background-color: #fff;
  border-radius: 15px;
  border-style: none;
  box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
  box-sizing: border-box;
  color: #3c4043;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-family: "Google Sans",Roboto,Arial,sans-serif;
  font-size: 20px;
  font-weight: 500;
  height: 300px;
  justify-content: center;
  letter-spacing: .25px;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 2px 70px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1),opacity 15ms linear 30ms,transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
  will-change: transform,opacity;
  z-index: 0;
}

.drop-container {
  position: relative;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  padding: 20px;
  border-radius: 10px;
  border: 2px dashed #555;
  color: #444;
  cursor: pointer;
  transition: background .2s ease-in-out, border .2s ease-in-out;
}

.drop-container:hover {
  background: #eee;
  border-color: #111;
}

.drop-container:hover .drop-title {
  color: #222;
}

.drop-title {
  color: #444;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  transition: color .2s ease-in-out;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

h5 {
  font-size: 1.8rem;
  font-weight: 600;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  text-align: center;
}

p {
  font-size: 1.2rem;
}

#upload-btn {
  width: 250px;
  height: 50px;
  letter-spacing: 2px;
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
}

.file-det {
  margin-top: 2rem;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.container {
    margin-top: 5rem;
}

.header {
    margin: 10px 0;
}

.emp-list-select {
    display: block;
    position: relative;
    margin: 30px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}

#emp-list {
    height: 65px;
}

.feedback-outer {
    display: flex;
    justify-content: center;
    padding: 20px 30p;
    height: 200px;
}

.feedback-inner {
    width: 100%;
    padding: 20px;
}

.emp-rating>div {
    width: 200px;
}

.emp-rating-outer {
    display: flex;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

#rate-text-div {
    margin-left: 20px;
}

#rate-text {
    font-size: 1.5rem;
}

.update-btn-div {
    position: relative;
    margin: 1rem auto;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    display: flex;
}

.display-rating {
    margin-top: 3rem;
}

.show-cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5rem;
}
.button-17 {
    align-items: center;
    -webkit-appearance: none;
            appearance: none;
    background-color: #fff;
    border-radius: 15px;
    border-style: none;
    box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
    box-sizing: border-box;
    color: #3c4043;
    cursor: pointer;
    display: inline-flex;
    fill: currentcolor;
    font-family: "Google Sans",Roboto,Arial,sans-serif;
    font-size: 20px;
    font-weight: 500;
    height: 50px;
    justify-content: center;
    letter-spacing: .25px;
    line-height: normal;
    max-width: 100%;
    overflow: visible;
    padding: 2px 70px;
    position: relative;
    text-align: center;
    text-transform: none;
    transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1),opacity 15ms linear 30ms,transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: auto;
    will-change: transform,opacity;
    z-index: 0;
  }
  
  .button-17:hover {
    background: #F6F9FE;
    color: #174ea6;
  }
  
  .button-17:active {
    box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%), 0 8px 12px 6px rgb(60 64 67 / 15%);
    outline: none;
  }
  
  .button-17:focus {
    outline: none;
    border: 2px solid #4285f4;
  }
  
  .button-17:not(:disabled) {
    box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
  }
  
  .button-17:not(:disabled):hover {
    box-shadow: rgba(60, 64, 67, .3) 0 2px 3px 0, rgba(60, 64, 67, .15) 0 6px 10px 4px;
  }
  
  .button-17:not(:disabled):focus {
    box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
  }
  
  .button-17:not(:disabled):active {
    box-shadow: rgba(60, 64, 67, .3) 0 4px 4px 0, rgba(60, 64, 67, .15) 0 8px 12px 6px;
  }
  
  .button-17:disabled {
    box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
  }



  
.example_g {
    font-family: 'Poppins';
    width: 320px;
    height: 50px;
    border: none;
    outline: none;
    color: #494949;
    background: #ffffff;
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 4px;
    cursor: pointer;
    position: relative;
    z-index: 0;
    transition: 0.5s ease-in-out;
}

.example_g:before {
    content: '';
    background: linear-gradient(45deg, #FF0000, #FFF700, #FF7000, #FF00FE, #3FF4EB, #9E00FF, #00BEFF, #FF00DD, #FF0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
}

.example_g:active {
    color: #000
}

.example_g:active:after {
    background: transparent;
}

.example_g:hover {
    transform: scale(1.05);
}

.example_g:hover:before {
    opacity: 1;
}

.example_g:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #ffffff;
    left: 0;
    top: 0;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}

.employee{
  margin-left: 3rem;
  margin-top: 2rem;
  align-items: center;
  -webkit-appearance: none;
          appearance: none;
  background-color: #fff;
  border-radius: 15px;
  border-style: none;
  box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
  box-sizing: border-box;
  color: #3c4043;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-family: "Google Sans",Roboto,Arial,sans-serif;
  font-size: 20px;
  font-weight: 500;
  height: 300px;
  justify-content: center;
  letter-spacing: .25px;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 2px 70px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1),opacity 15ms linear 30ms,transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
  will-change: transform,opacity;
  z-index: 0;
}

.drop-container {
  position: relative;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  padding: 20px;
  border-radius: 10px;
  border: 2px dashed #555;
  color: #444;
  cursor: pointer;
  transition: background .2s ease-in-out, border .2s ease-in-out;
}

.drop-container:hover {
  background: #eee;
  border-color: #111;
}

.drop-container:hover .drop-title {
  color: #222;
}

.drop-title {
  color: #444;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  transition: color .2s ease-in-out;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

h5 {
  font-size: 1.8rem;
  font-weight: 600;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  text-align: center;
}

p {
  font-size: 1.2rem;
}

#upload-btn {
  width: 250px;
  height: 50px;
  letter-spacing: 2px;
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
}

.file-det {
  margin-top: 2rem;
}
.comment-history{width:100%;height:150px;overflow-y:scroll;background:#fff;border:1px solid #e6e6e6;box-shadow:0 1px 3px rgba(0,0,0,.12),0 1px 2px rgba(0,0,0,.2)}.comment-history p{margin-bottom:10px}.add-comment-btn{background:#924a91;display:flex;justify-content:center;align-items:center}.add-comment-btn p{color:#fff}
.limit-field{
    padding: 30px !important;
}

.input label{
    font-size: 20px;
}

.p_40{
    padding: 40px !important;
}



.profile-button:hover{
    background-color: #979797;
}



.main-file-upload{width:100%;display:flex;flex-direction:column;justify-content:center;align-items:center;padding:40px 0}.main-file-upload .file-uploader{display:flex;align-items:center}
* {
  box-sizing: border-box;
  --theme: #162e70;
  --lightTheme: #496cce;

}

.leftContentHeader {
  width: auto;
  margin: 10px 0;
}

.leftContentHeader h2 {
  font-size: 2.5rem;
  color: #2c3e50;
}

.contentHeader {
  width: 100%;
  padding: 0px 10px 40px 10px;
}

.homeHeading {
  font-size: 2rem;
  margin-left: 0;
  font-weight: 300;
  font-weight: 500;
  color: #162e70;
}

.mainPara {
  font-size: 20px;
  font-weight: 400;
  color: #162e70;
  margin-top: 15px;
}

.card {
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: 280px;
  width: auto;
  margin: 5px;
}

.card>ul>li {
  word-wrap: break-word;
}

.card-head {
  font-size: 1.2rem;
}

.profile-card {
  height: 560px;
}

.card-main {
  display: flex;

  align-items: center;
  margin-left: 50px;
}

.cards-container {
  width: auto;
}

.card {
  margin: 10px !important;
}




.showmore {
  background-color: var(--theme);
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  line-height: 20px;
  list-style: none;
  margin: 0;
  outline: none;
  padding: 10px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color 100ms;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  box-shadow: 0 8px 10px 0px rgba(16, 16, 17, 0.5);
}

.showmore:hover {
  color: black;
  border-color: currentColor;
  background-color: #F082AC;
  box-shadow: 0 8px 10px 0px rgba(16, 16, 17, 0.5);
}

.cardsAnalytics {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  font-family: monospace;
  flex-wrap: wrap;
}

.analytics-page {
  font-family: 'Arial', sans-serif;
  margin: 20px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.analytics-boxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.analytics-box {
  width: 100px;
  flex: 1 1;
  background-color: #ecf0f1;
  color: #333;
  padding: 20px;
  margin: 10px;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;
}

h4 {
  /* color: #2c3e50; */
  font-weight: bold;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin-bottom: 5px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

th,
td {
  /* border: 1px solid #bdc3c7; */
  padding: 8px;
  text-align: left;
}

th {
  background-color: #3498db;
  color: #ecf0f1;
}

@media screen and (max-width: 1200px) {
  .analytics-box {
    flex: 0 0 48%;
  }
}

@media screen and (max-width: 768px) {
  .analytics-box {
    flex: 0 0 100%;
  }
}

.eye-icon {
  cursor: pointer;
  margin-left: 5px;
  font-size: 1.8rem;
  font-weight: bold;
}

.eye-icon:hover {
  cursor: pointer;
}

.analytics-boxesForPersonalinfo {
  flex-wrap: wrap;
  justify-content: space-between;
}

.analytics-boxForPersonalInfo {
  display: flex;
  background-color: #ecf0f1;
  color: #333;
  padding: 20px;
  margin: 10px;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.left-content {
  margin-right: 20px;
  /* color: #C27BA0; */
  color: var(--theme);
}

.left-content svg {
  width: 8.5rem;
  height: 6.5rem;
}

.right-content {
  flex-grow: 1;
}

.right-content span {
  font-weight: bold;
  font-size: 18px;
}

.right-content p {
  font-size: 14px;
  margin: 8px 0;
}

@media (max-width: 768px) {
  .right-content span {
    font-size: 16px;
  }

  .right-content p {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .analytics-boxForPersonalInfo {
    flex-direction: column;
  }

  .analytics-boxesForPersonalinfo {
    justify-content: flex-start;
  }

  .left-content svg {
    width: 4rem;
    height: 4rem;
  }
}

.custom-tooltip {
  position: relative;
  display: inline-block;
}

.custom-tooltip .tooltip-content {
  visibility: hidden;
  background-color: #333;
  color: #fff;
  font-weight: bolder;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.custom-tooltip:hover .tooltip-content {
  visibility: visible;
  opacity: 1;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
}

.mainHeading {
  font-weight: bold;
  color: var(--theme);
  font-size: 2.5rem;
  padding-left: 1.5rem;
  width: 100%;
}

/* profile info section */

.user-sec {
  margin-top: 2rem;
  margin-left: 20px !important;
  margin-right: 15px !important;
  padding-bottom: 10px;
  /* width: 94%; */
}

.user-Section {
  background-color: hsl(192, 15%, 94%);
  box-shadow: 2px 5px 15px rgb(145, 137, 137);
  height: 70px;
  border-radius: 10px;
  /* margin-left: 10px; */
}

.inner-cont {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  padding-left: 10px;
}

.inner-cont>div {
  width: 200px;
  text-align: center;
  padding-top: 20px;
}

.inner-cont>div>button::after {
  content: "";
  height: 3px;
  background-color: var(--theme);
  display: block;
  width: 0;
  transition: all .1s ease;
}

.inner-cont button:hover::after {
  width: 150px;
  transition: all .2s ease;
}


.inner-cont button {
  border: 0;
  outline: 0;
  text-align: center;
  letter-spacing: 2px;
  font-size: 1.3rem;
  font-family: inherit;
  background-color: transparent;
  color: #000;
}

/* User Details section */
.ud-container {
  display: flex;
  width: 94%;
  height: 550px;
  margin: 3rem;
  background-color: #ecf0f1;
  border-radius: 10px;
  box-shadow: 5px 5px 10px rgb(176, 172, 172);
  padding: 20px 25px;
  overflow-y: scroll;
}

.ud-left-outer {
  margin-left: 3rem;
}

.ud-left-inner>div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 25px 10px;
  width: 100%;
  cursor: pointer;
}

.ud-left-inner>div:hover {
  /* background-color: rgba(220, 217, 217, 0.651); */
  color: var(--lightTheme);
}

#ud-left-icon {
  margin-bottom: 0;
  margin-right: 8px;
  width: 30px;
  height: 30px;
  fill: var(--theme);
}

.ud-left-inner>div>h6 {
  font-weight: 700;
  padding-top: 10px;
  padding-left: 10px;
  font-size: 1.4rem;
  color: var(--theme);
  font-weight: 700;
}

.ud-left-inner>div>:hover {
  color: rgb(93, 136, 231);
  fill: var(--lightTheme);
  transition: all .2s ease-in;
  text-decoration: underline;
  text-shadow: 1px 1px 5px rgb(160, 85, 85);
}

.middle {
  padding-top: 2rem;
  /* height: 100%; */
}

.scroll-vertical {
  content: " ";
  height: 410px;
  width: 3px;
  background-color: rgb(193, 192, 192);
  margin-left: 3rem;
  margin-right: 2rem;
}

/* right section */
.ud-right {
  height: 100%;
  width: 100%;
  padding-top: 1rem;
}

.ud-right-cont {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-right: 2rem;
}



.ud-right-inner {
  display: flex;
  justify-content: space-between;
  width: auto;
}

/* Edit button for form */
.form-container {
  max-width: inherit;
}


.aboutSec>div>h6 {
  margin-bottom: 10px;
  font-size: 1.05rem;
  color: var(--theme);
  font-weight: 600;
  font-family: Georgia, 'Times New Roman', Times, serif;
  -webkit-text-decoration: dashed;
          text-decoration: dashed;
}

.dynamic-form-values,
.static-form-values {
  margin-bottom: 30px;
}

.dynamic-form-values h6,
.static-form-values>h6 {
  margin-bottom: 10px;
  font-size: 1.05rem;
  color: var(--theme);
  font-weight: 600;
  font-family: Georgia, 'Times New Roman', Times, serif;
  -webkit-text-decoration: dashed;
          text-decoration: dashed;
}

.aboutSec>div>p {
  width: 500px;
  height: 40px;
  padding: 8px;
  border: 1px solid #fff;
  border-radius: 10px;
  box-sizing: border-box;
  font-size: 1rem;
  letter-spacing: 2px;
  text-align: center;
  border-radius: 20px;
  box-shadow: inset 0px 1px 10px rgba(149, 146, 146);

}

.aboutSec>div {
  margin: 20px;
}

.aboutSec>div>input {
  width: 500px;
  padding: 8px;
  border: 0;
  outline: 0;
  background-color: transparent;
  box-sizing: border-box;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 1px;
  border-radius: 10px;
  box-shadow: 1px 1px 10px rgba(131, 154, 195, 0.82);
  padding-left: 40px;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.aboutSec>div>input:focus {
  border: 1px solid blue;
  color: var(--lightTheme);
  font-size: 1.2rem;
  font-weight: 500;
  transition: all .2s ease-in-out;
}

.aboutSec>div>input::placeholder {
  color: #848383;
  font-size: .9rem;
  font-weight: 400;
}

.dynamiv-form-values input:focus {
  outline: none;
  border-color: #007bff;
}

.save-btn button {
  background-color: var(--theme);
  width: 100px;
  color: #FFFFFF;
  border-radius: 10px;
  transition: all .2s ease-in-out;
  margin: 10px;
  padding: 5px;

}

.save-btn button:hover {
  background-color: blue;
}

.edit-btn {
  border: 2px solid #1b1618;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  padding: 5px;
  position: -webkit-sticky;
  position: sticky;
  transition: all .5s ease-in-out;
}

.edit-btn:hover {
  border: 2px solid rgb(61, 15, 224);
  color: var(--theme);
  transition: all .5s ease-in-out;
  transform: rotate3d(0, 0, 1, 360deg);
  ;
}

.user-biodata {
  margin-left: 1.5rem;
}

.work-profile>div>h6,
.user-bio>div>h6,
.user-dob>div>h6 {
  margin-bottom: 10px;
  font-size: 1.05rem;
  color: var(--theme);
  font-weight: 600;
  font-family: Georgia, 'Times New Roman', Times, serif;
  -webkit-text-decoration: dashed;
          text-decoration: dashed;
  margin-bottom: 1rem;
}



.work-profile>div>p,
.user-bio>div>p,
.user-dob>div>p {
  font-size: 1.3rem;
  font-weight: bold;
  font-family: 'Playfair Display', serif;
  letter-spacing: 3px;
  padding-left: 10px;
}

.rep-location>h3,
.add-details>h3,
.cont-dtl>h3,
.dep-details>h3 {
  font-family: 'Times New Roman', Times, serif;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 2rem;
  color: var(--theme);
}



.add-head,
.cont-head,
.dep-head h5 {
  margin-bottom: 2rem;
  color: var(--theme);
  font-family: 'Times New Roman', Times, serif;
  letter-spacing: 2px;
}

.cont-div,
.add-div,
.dep-div {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  /* margin-top: 2rem; */
  width: 100%;
}

.add-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  margin-left: 20px;
  width: 100px;
  height: 40px;
  font-size: 1.5rem;
  color: white;
  background-color: var(--theme);
  cursor: pointer;
  border: none;
  border-radius: 5px;
  box-shadow: 5px 3px 10px rgb(99, 121, 193);
  font-family: 'Times New Roman', Times, serif;
  transition: all .25s ease-in-out;
}

.add-btn:hover,
.cont-btn:hover,
.dep-btn:hover {
  height: 44px;
  width: 105px;
  transition: all .25s ease-in-out;
}

.add-img,
.cont-img,
.dep-img {
  align-self: center;
  min-width: 200px;
  max-width: 400px;
  margin-left: 100px;
  padding-right: 1rem;
}

.dep-details {
  width: 250px;
}

.rep-img {
  margin-left: 10px;
  width: 350px;

}

.cont-img {
  width: 310px;
}

.rep-city-cont,
.user-curr-exp {
  margin-top: 2rem;
  width: 250px;

}

.rep-city>div>h6,
.user-exp>div>h6 {
  color: #333;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  line-break: auto;
  font-family: 'Times New Roman', Times, serif;
  margin-bottom: 1rem;
}

.loc-btn {
  height: 20px;
  width: 60px;
  font-size: .9rem;
  padding: 0;
  margin-left: 0;
  background-color: grey;
  box-shadow: none;
  transition: all .25s ease-in-out;
}

.loc-btn:hover {
  height: 22px;
  width: 65px;
  background-color: var(--theme);
  box-shadow: 5px 3px 10px var(--lightTheme);
  transition: all .25s ease-in-out;
}


.input-type-file {
  border: 1px dashed var(--theme);
  padding: 10px;
  margin: 2rem;
}

.contEditSec>div {
  margin: 10px;
}

.contEditSec>div>h5,
.editDependents>div>h5 {
  font-size: 1rem;
  color: var(--theme);
  display: flex;
  margin: 5px 0;
}


/* For small screens */

@media only screen and (min-width: 250px) and (max-width:500px) {

  .card-main {
    display: block;
    margin-left: 0 !important;
  }

  .cards-container {
    display: block;
    width: auto !important;
    margin-left: auto;
  }

  .card-comp {
    margin: 0 !important;
  }

  .user-card {
    width: auto !important;
  }

  .left-card-cont {
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .userDetails {
    padding: 0 !important;
  }

  .user-item {
    width: auto !important;
  }

  .profile-card {
    height: 100%;
  }

  .card {
    flex: 1 1 100%;
    margin: 5px 0 !important;
  }

  .inner-cont button {
    font-size: .9rem !important;
  }

  .scroll-vertical {
    margin-left: 20px !important;
  }


  .user-biodata {
    margin-left: 0 !important;
  }

  .user-sec {
    margin-left: 0 !important;
    width: auto !important;
  }

  .user-Section {
    margin-left: 0 !important;

  }

  .ud-left-outer {
    margin-left: 10px !important;

  }

  .user-Section button {
    font-size: .6rem;
  }

  .ud-container {
    padding: 10px 5px !important;
    width: 90% !important;
    margin: 1rem !important;
  }

  .aboutSec>div>p {
    width: 200px !important;
    letter-spacing: 0;
  }

  .cont-div,
  .add-div,
  .dep-div {
    display: inline-block !important;
    margin-top: 2rem;
    width: auto !important;
  }

  .add-img,
  .dep-img,
  .cont-img {
    width: 210px !important;
    height: 200px !important;
    margin-left: 10px;
    padding-right: 10px;
  }

  .doc-img {
    display: block;
    padding-top: 0 !important;
    width: 200px;
    height: 160px;
  }

  .exp-img {
    display: none;
  }
}


@media only screen and (min-width: 500px) and (max-width:700px) {

  .card-main {
    display: block;
    margin-left: 0 !important;
  }

  .cards-container {
    display: block;
    width: auto !important;
    margin-left: auto;
  }

  .user-card {
    width: auto !important;
  }

  .profile-card {
    justify-content: space-evenly;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  .card {
    flex: 1 1 100%;
    margin: 10px !important;
  }

  .card svg {
    width: 15px !important;
    height: 15px !important
  }

  .inner-cont button {
    font-size: 1.1rem !important;
  }

  .scroll-vertical {
    margin-left: 20px !important;
  }

  .user-biodata {
    margin-left: 0 !important;
  }

  .user-Section {
    margin-left: 0 !important;
  }

  .ud-container {
    padding: 10px 5px !important;
    width: 98% !important;
    margin: 3rem 1rem;
  }

  .ud-left-outer {
    margin-left: 10px !important;
  }

  .aboutSec>div>p {
    width: 220px !important;
  }
  
  .cont-div,
  .add-div,
  .dep-div {
    display: inline-block !important;
    margin-top: 2rem;
    width: auto !important;
  }

  .add-img,
  .dep-img,
  .cont-img {
    width: 260px !important;
    height: 240px !important;
    margin-left: 10px;
    padding-right: 10px;
  }

  /* .add-exp {
    display: none;
  } */

  .doc-img,
  .exp-img {
    display: block;
    padding-top: 0 !important;
    width: 200px;
    height: 160px;
  }

  .add-exp {
    display: none !important;
  }
}

/* For medium screens */
@media only screen and (min-width: 700px) and (max-width: 1000px) {

  .cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: 20px;
  }

  .user-card {
    width: 220px !important;
  }

  .card {
    flex: 1 1 40%;
    margin: 10px !important;
  }

  .card svg {
    width: 15px !important;
    height: 15px !important
  }

  .ud-left-outer {

    margin-left: 0 !important;
  }

  .scroll-vertical {
    margin-left: 20px !important;
  }

  .user-biodata {
    margin-left: 0 !important;
  }


}

/* For large screens */
@media only screen and (min-width: 1000px) {
  .cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .card {
    flex: 1 1 40%;
    margin: 10px 0;
  }


}


.centerItem{
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.ExpTitle{
  color: #413c9f !important;
  font-size: xx-large !important;
  font-weight: 700;
}
.companyName{
  font-size: 25px !important;
  font-weight: 700 !important;
}
.validationError{
  color: #f44336;
  font-size:0.75rem;
  font-weight:400;
  line-height:1.66;
  margin-top:3px;
  margin-left:14px;
  margin-right:14px;
  letter-spacing:0.03333em;
}

.uploadField{
  width: 100%;
  margin: 0;
  padding: 7px !important;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 5px;
  height: 40px !important;
  cursor: pointer;
}
       
.uploadInput input{
  color: black !important;
  padding: 8.5px 14px !important;
}
       
.uploadInput label{
  transform: translate(14px, -6px) scale(0.75) !important;
  padding: 0 5px;
  background: white !important;
}

.editButton{
  border: 1px solid rgba(0, 0, 0, 0.23);
  background: none;
  padding: 7px;
  border-radius: 5px;
}

.disableDate :disabled{
  background-color:  #EBEBE4;
}
.disableDate input:disabled{
  color: gray !important;
}

.showCredential{
  margin-top: 12px;
  padding: 10px;
  border-radius: 20px;
  font-size: 15px;
  font-weight: 500;
}

.medicalUploadField{
  width: 100%;
  margin: 0;
  padding: 7px !important;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 5px;
  height: 44px !important;
  cursor: pointer;
}
.input .MuiSelect-root{padding:18.5px 14px !important}.input input{color:#000 !important;padding:18.5px 14px !important;height:1px}.input label{transform:translate(14px, -6px) scale(0.75) !important;padding:0 5px;background:#fff !important}
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

h5 {
    font-size: 1.8rem;
    font-weight: 600;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    text-align: center;
}

p {
    font-size: 1.2rem;
}

#upload-btn {
    width: 250px;
    height: 50px;
    letter-spacing: 2px;
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
}

.file-det {
    margin-top: 2rem;
}
.cardsAppointment {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.cardsAppointment>div {
  background: rgb(14, 63, 108);

  box-shadow: 0 8px 10px 0px rgba(16, 16, 17, 0.5);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  width: 20%;
  margin: 20px 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-around;

  padding: 15px;
}

.cardsAppointment>div>h3 {
  text-align: center;
  font-size: 0.7vw;
  color: white;
}

.cardsAppointment>div>h2 {
  text-align: center;
  font-size: 2vw;
  color: white;
}

@media screen and (max-width: 720px) {
  .pccDashContainer {
    width: 100%;
    margin: auto;
    height: calc(100vh - 120px);
    overflow-y: scroll;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px;
  }

  .cardsAppointment>div {
    width: 100%;
   
  }

  .cardsAppointment>div>h3 {
    text-align: center;
    font-size: 2vw;
    color: white;
  }

  .cardsAppointment>div>h2 {
    text-align: center;
    font-size: 5vw;
    color: white;
  }

  .pccDashContainer>div>h3 {
    font-size: 5vw;
    margin: 30px 0px 20px 0px;
    color: rgba(66, 58, 58, 0.87);
  }
}

.attendhello {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: #FBF9F1;
  border: 20px;
  border-radius: 10px;
  padding: 50px; 
  margin:  auto;
  /* margin-left: 500px; */
  color: #0a0a0a;
  box-shadow: 1px 1px 10px 0px rgb(12, 12, 43);
  font-size: 20px;
  
}
.box {
  border-radius: 10px;
  margin-top: 1rem;
  width: 50%;
  height: 150px;
  padding: 10px 10px 10px 10px;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 10px 0px red;
}

.tex {
  padding: 1rem;
  border: 1px solid #E5E1DA;
  border-radius: 10px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 1rem;
  background-color: #e0e0e0;;
  color:rgba(0, 0, 0, 0.87);;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}

.but {
  padding: 0.5rem 5rem;
  /* background-color: #92C7CF; */
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin:auto

}

.but:hover {
  /* background-color: #aad9bc; */
  background-color:rgb(105, 63, 105);
  
}
a:hover {
    background-color: rgb(228, 228, 215);
  }
.input .MuiSelect-root{padding:18.5px 14px !important}.input input{color:#000 !important;padding:18.5px 14px !important}.input label{transform:translate(14px, -6px) scale(0.75) !important;padding:0 5px;background:#fff !important}.MuiOutlinedInput-input{padding:none !important}.MuiOutlinedInput-input{padding:3px 11px !important}.MuiOutlinedInput-input .inline{display:flex !important}.MuiAutocomplete-inputRoot[class*=MuiOutlinedInput-root]{padding:0 !important}
.header {
    text-align: end;
    background-color: #dad7d757;
    height: 8vh;
    border-radius: 10px;
  }
  
  .body {
    margin-top: 15px;
    /* box-shadow: -1px 4px 20px -6px rgba(0, 0, 0, 0.7); */
    background-color: #dad7d757;
    border-radius: 10px;
    height: 100%
  }

  .cardsAnalytics {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    font-family: monospace;
    padding: 40px 15px 0px 15px;
  }

  .startPageDiv {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    font-family: monospace;
    padding: 40px 15px 0px 15px;
  }

  .answerSheetDiv {
    /* display: flex; */
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    /* justify-content: flex-start; */
    font-family: monospace;
    padding: 40px 15px 0px 15px;
  }
  
  .cardsAnalytics > div {
    background-color: white;
    box-shadow: 0 8px 10px 0px rgba(16, 16, 17, 0.5);
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    width: 15%;
    /* margin: 0px 0px 30px 0px; */
    display: flex;
    align-items: right;
    flex-direction: column;
    padding: 15px;
  }

  .startPageDiv > div {
    background-color: white;
    box-shadow: 0 8px 10px 0px rgba(16, 16, 17, 0.5);
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    width: 15%;
    /* margin: 0px 0px 30px 0px; */
    display: flex;
    align-items: right;
    flex-direction: column;
    padding: 15px;
  }

  .answerSheetDiv > div {
    display: flex;
    align-items: right;
    flex-direction: column;
    padding: 15px;
  }

  .cardsAnalytics > .MuiButton-label {
    color: gray !important;
  }

  .startPageDiv > .MuiButton-label {
    color: gray !important;
  }

  .answerSheetDiv > .MuiButton-label {
    color: gray !important;
  }

  .slide > .MuiSlider-root.Mui-disabled {
    color: #52af77 !important;
  }

  .buttonPrimary {
    border: none;
    color: #fff;
    /* background-image: linear-gradient(30deg, #0400ff, #4ce3f7); */
    
    border-radius: 20px;
    background-size: 100% auto;
    font-family: inherit;
    font-size: 17px;
    padding: 0.6em 1.5em;
   }
   .buttonSecondary {
    border: none;
    color: #fff;
    /* background-image: linear-gradient(30deg, #662D8C, #ED1E79); */
    border-radius: 20px;
    background-size: 100% auto;
    font-family: inherit;
    font-size: 17px;
    padding: 0.6em 1.5em;
   }
   
   /* .buttonPrimary:active {
    transform: translate(1px ,0px);
    box-shadow: 0px 1px 0px rgb(124, 109, 189);
    padding-bottom: 3px;
  } */
   
   .buttonPrimary:hover {
    background-position: right center;
    background-size: 200% auto;
    animation: pulse512 1.5s infinite;
   }
   .buttonSecondary:hover {
    background-position: right center;
    background-size: 200% auto;
    animation: pulse513 1.5s infinite;
   }
   @keyframes pulse512 {
    0% {
     box-shadow: 0 0 0 0 #05bada66;
    }
   
    70% {
     box-shadow: 0 0 0 10px rgb(218 103 68 / 0%);
    }
   
    100% {
     box-shadow: 0 0 0 0 rgb(218 103 68 / 0%);
    }
   }

   @keyframes pulse513 {
    0% {
     box-shadow: 0 0 0 0 #e90a0a66;
    }
   
    70% {
     box-shadow: 0 0 0 10px rgba(216, 72, 67, 0);
    }
   
    100% {
     box-shadow: 0 0 0 0 rgb(218 103 68 / 0%);
    }
   }

   /* .inputdesign {
    border: 2px solid transparent;
    width: 15em;
    height: 2.5em;
    padding-left: 0.8em;
    outline: none;
    overflow: hidden;
    background-color: #F3F3F3;
    border-radius: 10px;
    transition: all 0.5s;
  } */
  
  .inputdesign:hover,
  .inputdesign:focus {
    border: 2px solid #4A9DEC;
    box-shadow: 0px 0px 0px 7px rgb(74, 157, 236, 20%);
    background-color: white;
  }
  
.button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    grid-gap: 15px;
    gap: 15px;
    background-color: #007ACC;
    outline: 3px #007ACC solid;
    outline-offset: -3px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: 400ms;
  }
  
  .button .text {
    color: #007ACC;
    font-weight: 700;
    font-size: 1em;
    transition: 400ms;
  }
  
  .button svg path {
    transition: 400ms;
  }
  
  .button:hover {
    background-color: transparent;
  }
  
  .button:hover .text {
    color: #007ACC;
  }
  
  .button:hover svg path {
    fill: #007ACC;
  }
.MuiPagination-ul{margin:0;display:flex;padding:0;flex-wrap:inherit;list-style:none;align-items:center}.data-table svg{cursor:pointer}.data-table .MuiTableCell-root{padding:4px 4px !important}.data-table th,.data-table td{font-size:13px !important;min-width:8rem !important}



.profile-button:hover{
    background-color: #979797;
}



.main-file-upload{width:100%;display:flex;flex-direction:column;justify-content:center;align-items:center;padding:40px 0}.main-file-upload .file-uploader{display:flex;align-items:center}
.userDetails {
  padding: 20px 0;
}


.user-item {
  width: 100%;
  padding: 5px 40px;
  margin: 20px auto;
  cursor: pointer;
  /* Change cursor to pointer on hover */
  transition: background-color 0.3s;
  /* Smooth transition for background color */
  border: 2px solid black;
  border-radius: 20px;
  background-color: rgb(14, 63, 108);
  color: white
}

.user-item:hover {
  background-color: black;
  /* Change background color on hover */
  color: white;
}

/* Add icon styles */
.user-item svg {
  margin-right: 10px;
  /* Add margin to the right of the icon */
}





.cards-container {
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  margin: 5px auto;
  /* Allow items to wrap to the next line */
  justify-content: space-between;
  /* Adjust the spacing between cards */
}

.card {
  width: calc(50% - 20px);
  /* Set card width (50% of container width minus margin) */
  margin-bottom: 20px;
  /*Adjust the spacing between rows */
}

.list-item {
  display: flex;
  align-items: center;
  padding-left: 5px;

}


.hover-scrollbar::-webkit-scrollbar {
  width: 6px;
  /* Width of the scrollbar */
}

.hover-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Color of the track */
}

.hover-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  /* Color of the thumb */
  border-radius: 3px;
  /* Roundness of the thumb */
}

.hover-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* Color of the thumb on hover */
}

* {
  box-sizing: border-box;
}

.leftContentHeader {
  width: 100%;
}

.contentHeader {
  width: 100%;
  padding: 0px 10px 40px 10px;
}

.homeHeading {
  font-size: 2rem;
  margin-left: 0;
  font-weight: 300;
  font-weight: 500;
  color: #162e70;
}

.mainPara {
  font-size: 20px;
  font-weight: 400;
  color: #162e70;
  margin-top: 15px;
}

.showmore {
  background-color: #EA4C89;
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  line-height: 20px;
  list-style: none;
  margin: 0;
  outline: none;
  padding: 10px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color 100ms;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  box-shadow: 0 8px 10px 0px rgba(16, 16, 17, 0.5);
}

.showmore:hover {
  color: black;
  border-color: currentColor;
  background-color: #F082AC;
  box-shadow: 0 8px 10px 0px rgba(16, 16, 17, 0.5);
}

.cardsAnalytics {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  font-family: monospace;
  flex-wrap: wrap;
}

.analytics-page {
  font-family: 'Arial', sans-serif;
  margin: 20px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.analytics-boxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.analytics-box {
  width: 100px;
  flex: 1 1;
  background-color: #ecf0f1;
  color: #333;
  padding: 20px;
  margin: 10px;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;
}

h2 {
  color: #2c3e50;
}

h4 {
  /* color: #2c3e50; */
  font-weight: bold;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin-bottom: 5px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

th,
td {
  /* border: 1px solid #bdc3c7; */
  padding: 8px;
  text-align: left;
}

th {
  background-color: #3498db;
  color: #ecf0f1;
}

@media screen and (max-width: 1200px) {
  .analytics-box {
    flex: 0 0 48%;
  }
}

@media screen and (max-width: 768px) {
  .analytics-box {
    flex: 0 0 100%;
  }
}

.eye-icon {
  cursor: pointer;
  margin-left: 5px;
  font-size: 1.8rem;
  font-weight: bold;
}

.eye-icon:hover {
  cursor: pointer;
}

.analytics-boxesForPersonalinfo {
  flex-wrap: wrap;
  justify-content: space-between;
}

.analytics-boxForPersonalInfo {
  display: flex;
  background-color: #ecf0f1;
  color: #333;
  padding: 20px;
  margin: 10px;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.left-content {
  margin-right: 20px;
  color: #C27BA0;
}

.left-content svg {
  width: 8.5rem;
  height: 6.5rem;
}

.right-content {
  flex-grow: 1;
}

.right-content span {
  font-weight: bold;
  font-size: 18px;
}

.right-content p {
  font-size: 14px;
  margin: 8px 0;
}

@media (max-width: 768px) {
  .right-content span {
    font-size: 16px;
  }

  .right-content p {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .analytics-boxForPersonalInfo {
    flex-direction: column;
  }

  .analytics-boxesForPersonalinfo {
    justify-content: flex-start;
  }

  .left-content svg {
    width: 4rem;
    height: 4rem;
  }
}

.custom-tooltip {
  position: relative;
  display: inline-block;
}

.custom-tooltip .tooltip-content {
  visibility: hidden;
  background-color: #333;
  color: #fff;
  font-weight: bolder;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.custom-tooltip:hover .tooltip-content {
  visibility: visible;
  opacity: 1;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
}

.mainHeading {
  font-weight: bold;
  color: #EA4C89;
  font-size: 2.5rem;
  padding-left: 1.5rem;
  width: 100%;

}

/* profile info section */

.user-sec {
  margin-top: 2rem;
  margin-left: 20px;
  padding-bottom: 10px;
}

.user-Section {
  background-color: hsl(192, 15%, 94%);
  box-shadow: 2px 5px 15px rgb(145, 137, 137);
  height: 70px;
  border-radius: 10px;
  margin-left: 10px;
}

.inner-cont {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  /* padding-bottom: 10px; */

}

.inner-cont>div {
  width: 200px;
  text-align: center;
  padding-top: 20px;
}

.inner-cont>div>button::after {
  content: "";
  height: 3px;
  background-color: #EA4C89;
  display: block;
  transition: all 0.3s ease;
}

.inner-cont button:focus::after {
  width: 150px;
}


.inner-cont button {
  border: 0;
  outline: 0;
  text-align: center;
  letter-spacing: 2px;
  font-size: 1.3rem;
  font-family: inherit;
  background-color: transparent;
  color: #000;
}

/* User Details section */
.ud-container {
  background-color: #ecf0f1;
  border-radius: 10px;
  box-shadow: 5px 5px 10px rgb(176, 172, 172);
  padding: 20px 25px;
}

.ud-left-outer {
  margin-left: 3rem;
}

.ud-left-inner>div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 25px 10px;
  width: 100%;
  cursor: pointer;
}

.ud-left-inner>div:hover {
  /* background-color: rgba(220, 217, 217, 0.651); */
  color: #EA4C89;
}

.ud-left-inner img {
  margin-bottom: 0;
  margin-right: 8px;
  width: 30px;
  height: 30px;
}

.ud-left-inner>div>h6 {
  font-weight: 400;
  padding-top: 10px;
  padding-left: 10px;
  font-size: 1.3rem;

}

.ud-left-inner>div>:hover {
  color: #EA4C89;
  fill: #EA4C89;
  transition: all .2s ease-in;
  scale: -2px;
}



.scroll-vertical {
  content: " ";
  height: 450px;
  width: 3px;
  background-color: rgb(193, 192, 192);
  display: absolute;
  margin-left: 7rem;
  padding-top: 1rem;
}

/* right section */
.ud-right {
  height: 100%;
  width: 100%;
  padding-top: 1.5rem;
}

.ud-right-inner {
  display: flex;
  justify-content: space-between;
}

/* .edit-btn{
      border: 1px solid #EA4C89;
      border-radius: 50%;
      fill: #EA4C89;
      width: 35px;
      height: 35px;
      position: absolute;        
      /* bottom: 10%; */
/* right:80px;
      padding:5px
  } */



.user-biodata {
  margin-left: 2.5rem;
}

.add-details>h3,
.cont-dtl>h3,
.dep-details>h3 {
  font-family: 'Times New Roman', Times, serif;
  font-size: 2rem;
  font-weight: 600;
}

.user-about>div>h6,
.user-bio>div>h6,
.user-dob>div>h6 {
  color: #ea4c89dc;
  letter-spacing: 1px;
}

.user-about>div>p,
.user-bio>div>p,
.user-dob>div>p {
  font-size: 1.3rem;
  font-weight: bold;
  font-family: 'Playfair Display', serif;
  letter-spacing: 3px;
  padding-left: 10px;
}

.add-details>h3,
.cont-dtl>h3,
.dep-details>h3 {
  font-family: 'Times New Roman', Times, serif;
  font-size: 2rem;
  font-weight: 600;

}

.add-head,
.cont-head,
.dep-head {
  margin-top: 3rem;
}

.add-head,
.cont-head,
.dep-head h6 {
  color: rgb(160, 157, 157);
  font-family: 'Times New Roman', Times, serif;
  letter-spacing: 2px;
}

.add-btn,
.cont-btn,
.dep-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  margin-left: 20px;
  width: 100px;
  height: 40px;
  font-size: 1.5rem;
  color: white;
  background-color: #EA4C89;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  box-shadow: 5px 3px 10px rgb(198, 132, 143);
  font-family: 'Times New Roman', Times, serif;
  transition: all .25s ease-in-out;
}

.add-btn:hover,
.cont-btn:hover,
.dep-btn:hover {
  height: 44px;
  width: 105px;
  transition: all .25s ease-in-out;
}

.add-img,
.cont-img,
.dep-img {

  width: 350px;
}

.rep-city-cont,
.user-curr-exp {
  margin-top: 2rem;
}

.rep-city>div>p,
.user-exp>div>p {
  color: #333;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  line-break: auto;
}

.rep-location>h5 {
  font-size: 1.5rem;
  width: 100%;
  font-weight: 700;
  margin-top: 0;
  color: #171515;
  text-shadow: 2px 2px 10px grey;
}

.loc-btn {
  height: 20px;
  width: 60px;
  font-size: .9rem;
  padding: 0;
  margin-left: 0;
  background-color: grey;
  box-shadow: none;
  transition: all .25s ease-in-out;
}

.loc-btn:hover {
  height: 22px;
  width: 65px;
  background-color: #EA4C89;
  box-shadow: 5px 3px 10px rgb(198, 132, 143);
  transition: all .25s ease-in-out;
}
.input .MuiSelect-root{padding:18.5px 14px !important}.input input{color:#000 !important;padding:18.5px 14px !important;height:1px}.input label{transform:translate(14px, -6px) scale(0.75) !important;padding:0 5px;background:#fff !important}



.profile-button:hover{
    background-color: #979797;
}



.main-file-upload{width:100%;display:flex;flex-direction:column;justify-content:center;align-items:center;padding:40px 0}.main-file-upload .file-uploader{display:flex;align-items:center}
.userDetails {
  padding: 20px 0;
}


.user-item {
  width: 100%;
  padding: 5px 40px;
  margin: 20px auto;
  cursor: pointer;
  /* Change cursor to pointer on hover */
  transition: background-color 0.3s;
  /* Smooth transition for background color */
  border: 2px solid black;
  border-radius: 20px;
  background-color: rgb(14, 63, 108);
  color: white
}

.user-item:hover {
  background-color: black;
  /* Change background color on hover */
  color: white;
}

/* Add icon styles */
.user-item svg {
  margin-right: 10px;
  /* Add margin to the right of the icon */
}





.cards-container {
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  margin: 5px auto;
  /* Allow items to wrap to the next line */
  justify-content: space-between;
  /* Adjust the spacing between cards */
}

.card {
  width: calc(50% - 20px);
  /* Set card width (50% of container width minus margin) */
  margin-bottom: 20px;
  /*Adjust the spacing between rows */
}

.list-item {
  display: flex;
  align-items: center;
  padding-left: 5px;

}


.hover-scrollbar::-webkit-scrollbar {
  width: 6px;
  /* Width of the scrollbar */
}

.hover-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Color of the track */
}

.hover-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  /* Color of the thumb */
  border-radius: 3px;
  /* Roundness of the thumb */
}

.hover-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* Color of the thumb on hover */
}

* {
  box-sizing: border-box;
}

.leftContentHeader {
  width: 100%;
}

.contentHeader {
  width: 100%;
  padding: 0px 10px 40px 10px;
}

.homeHeading {
  font-size: 2rem;
  margin-left: 0;
  font-weight: 300;
  font-weight: 500;
  color: #162e70;
}

.mainPara {
  font-size: 20px;
  font-weight: 400;
  color: #162e70;
  margin-top: 15px;
}

.showmore {
  background-color: #EA4C89;
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  line-height: 20px;
  list-style: none;
  margin: 0;
  outline: none;
  padding: 10px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color 100ms;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  box-shadow: 0 8px 10px 0px rgba(16, 16, 17, 0.5);
}

.showmore:hover {
  color: black;
  border-color: currentColor;
  background-color: #F082AC;
  box-shadow: 0 8px 10px 0px rgba(16, 16, 17, 0.5);
}

.cardsAnalytics {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  font-family: monospace;
  flex-wrap: wrap;
}

.analytics-page {
  font-family: 'Arial', sans-serif;
  margin: 20px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.analytics-boxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.analytics-box {
  width: 100px;
  flex: 1 1;
  background-color: #ecf0f1;
  color: #333;
  padding: 20px;
  margin: 10px;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;
}

h2 {
  color: #2c3e50;
}

h4 {
  /* color: #2c3e50; */
  font-weight: bold;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin-bottom: 5px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

th,
td {
  /* border: 1px solid #bdc3c7; */
  padding: 8px;
  text-align: left;
}

th {
  background-color: #3498db;
  color: #ecf0f1;
}

@media screen and (max-width: 1200px) {
  .analytics-box {
    flex: 0 0 48%;
  }
}

@media screen and (max-width: 768px) {
  .analytics-box {
    flex: 0 0 100%;
  }
}

.eye-icon {
  cursor: pointer;
  margin-left: 5px;
  font-size: 1.8rem;
  font-weight: bold;
}

.eye-icon:hover {
  cursor: pointer;
}

.analytics-boxesForPersonalinfo {
  flex-wrap: wrap;
  justify-content: space-between;
}

.analytics-boxForPersonalInfo {
  display: flex;
  background-color: #ecf0f1;
  color: #333;
  padding: 20px;
  margin: 10px;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.left-content {
  margin-right: 20px;
  color: #C27BA0;
}

.left-content svg {
  width: 8.5rem;
  height: 6.5rem;
}

.right-content {
  flex-grow: 1;
}

.right-content span {
  font-weight: bold;
  font-size: 18px;
}

.right-content p {
  font-size: 14px;
  margin: 8px 0;
}

@media (max-width: 768px) {
  .right-content span {
    font-size: 16px;
  }

  .right-content p {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .analytics-boxForPersonalInfo {
    flex-direction: column;
  }

  .analytics-boxesForPersonalinfo {
    justify-content: flex-start;
  }

  .left-content svg {
    width: 4rem;
    height: 4rem;
  }
}

.custom-tooltip {
  position: relative;
  display: inline-block;
}

.custom-tooltip .tooltip-content {
  visibility: hidden;
  background-color: #333;
  color: #fff;
  font-weight: bolder;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.custom-tooltip:hover .tooltip-content {
  visibility: visible;
  opacity: 1;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
}

.mainHeading {
  font-weight: bold;
  color: #EA4C89;
  text-align: center;
  font-size: 2.5rem;
  padding-left: 1.5rem;
  width: 100%;
  color: rgb(103, 37, 103);
}

/* profile info section */

.user-sec {
  margin-top: 2rem;
  margin-left: 20px;
  padding-bottom: 10px;
}

.user-Section {
  background-color: hsl(192, 15%, 94%);
  box-shadow: 2px 5px 15px rgb(145, 137, 137);
  height: 70px;
  border-radius: 10px;
  margin-left: 10px;
}

.inner-cont {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  /* padding-bottom: 10px; */

}

.inner-cont>div {
  width: 200px;
  text-align: center;
  padding-top: 20px;
}

.inner-cont>div>button::after {
  content: "";
  height: 3px;
  background-color: #EA4C89;
  display: block;
  transition: all 0.3s ease;
}

.inner-cont button:focus::after {
  width: 150px;
}


.inner-cont button {
  border: 0;
  outline: 0;
  text-align: center;
  letter-spacing: 2px;
  font-size: 1.3rem;
  font-family: inherit;
  background-color: transparent;
  color: #000;
}

/* User Details section */
.ud-container {
  background-color: #ecf0f1;
  border-radius: 10px;
  box-shadow: 5px 5px 10px rgb(176, 172, 172);
  padding: 20px 25px;
}

.ud-left-outer {
  margin-left: 3rem;
}

.ud-left-inner>div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 25px 10px;
  width: 100%;
  cursor: pointer;
}

.ud-left-inner>div:hover {
  /* background-color: rgba(220, 217, 217, 0.651); */
  color: #EA4C89;
}

.ud-left-inner img {
  margin-bottom: 0;
  margin-right: 8px;
  width: 30px;
  height: 30px;
}

.ud-left-inner>div>h6 {
  font-weight: 400;
  padding-top: 10px;
  padding-left: 10px;
  font-size: 1.3rem;

}

.ud-left-inner>div>:hover {
  color: #EA4C89;
  fill: #EA4C89;
  transition: all .2s ease-in;
  scale: -2px;
}



.scroll-vertical {
  content: " ";
  height: 450px;
  width: 3px;
  background-color: rgb(193, 192, 192);
  display: absolute;
  margin-left: 7rem;
  padding-top: 1rem;
}

/* right section */
.ud-right {
  height: 100%;
  width: 100%;
  padding-top: 1.5rem;
}

.ud-right-inner {
  display: flex;
  justify-content: space-between;
}

/* .edit-btn{
      border: 1px solid #EA4C89;
      border-radius: 50%;
      fill: #EA4C89;
      width: 35px;
      height: 35px;
      position: absolute;        
      /* bottom: 10%; */
/* right:80px;
      padding:5px
  } */



.user-biodata {
  margin-left: 2.5rem;
}

.add-details>h3,
.cont-dtl>h3,
.dep-details>h3 {
  font-family: 'Times New Roman', Times, serif;
  font-size: 2rem;
  font-weight: 600;
}

.user-about>div>h6,
.user-bio>div>h6,
.user-dob>div>h6 {
  color: #ea4c89dc;
  letter-spacing: 1px;
}

.user-about>div>p,
.user-bio>div>p,
.user-dob>div>p {
  font-size: 1.3rem;
  font-weight: bold;
  font-family: 'Playfair Display', serif;
  letter-spacing: 3px;
  padding-left: 10px;
}

.add-details>h3,
.cont-dtl>h3,
.dep-details>h3 {
  font-family: 'Times New Roman', Times, serif;
  font-size: 2rem;
  font-weight: 600;

}

.add-head,
.cont-head,
.dep-head {
  margin-top: 3rem;
}

.add-head,
.cont-head,
.dep-head h6 {
  color: rgb(160, 157, 157);
  font-family: 'Times New Roman', Times, serif;
  letter-spacing: 2px;
}

.add-btn,
.cont-btn,
.dep-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  margin-left: 20px;
  width: 100px;
  height: 40px;
  font-size: 1.5rem;
  color: white;
  background-color: #EA4C89;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  box-shadow: 5px 3px 10px rgb(198, 132, 143);
  font-family: 'Times New Roman', Times, serif;
  transition: all .25s ease-in-out;
}

.add-btn:hover,
.cont-btn:hover,
.dep-btn:hover {
  height: 44px;
  width: 105px;
  transition: all .25s ease-in-out;
}

.add-img,
.cont-img,
.dep-img {

  width: 350px;
}

.rep-city-cont,
.user-curr-exp {
  margin-top: 2rem;
}

.rep-city>div>p,
.user-exp>div>p {
  color: #333;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  line-break: auto;
}

.rep-location>h5 {
  font-size: 1.5rem;
  width: 100%;
  font-weight: 700;
  margin-top: 0;
  color: #171515;
  text-shadow: 2px 2px 10px grey;
}

.loc-btn {
  height: 20px;
  width: 60px;
  font-size: .9rem;
  padding: 0;
  margin-left: 0;
  background-color: grey;
  box-shadow: none;
  transition: all .25s ease-in-out;
}

.loc-btn:hover {
  height: 22px;
  width: 65px;
  background-color: #EA4C89;
  box-shadow: 5px 3px 10px rgb(198, 132, 143);
  transition: all .25s ease-in-out;
}
.input .MuiSelect-root{padding:18.5px 14px !important}.input input{color:#000 !important;padding:18.5px 14px !important;height:1px}.input label{transform:translate(14px, -6px) scale(0.75) !important;padding:0 5px;background:#fff !important}
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    --theme: #413c9f;
}

.formItem {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    padding: 20px;
    /* margin: 20px; */

}

#jobTitle,
#designation-select,
#location-select,
#currency-select {
    width: 400px;
    padding-left: 20px;
    height: 35px;
    border-radius: 10px;

}

hr {
    position: relative;
    /* top: 10px; */
    border: none;
    height: 0.1px;
    background-color: rgb(181, 180, 180);
    box-shadow: .5px .5px 3px #d8d7d7;
    /* margin-bottom: 50px; */

}

h5 {
    padding: 5px;
}

#checkBox {
    border: 1px dashed white;
    cursor: pointer;
    box-shadow: -1px -1px 5px rgb(181, 180, 180);
    border-radius: 10px;
    padding-right: 5px;
    width: 180px;
}

#submit-btn {
    display: inline-block;
    background-color: #0e3f6c;
    color: white;
    letter-spacing: 2px;
    left: 80%;
    width: 120px;
    transition: all.2s ease-out;
}

#submit-btn:hover {
    background-color: rgb(39, 39, 169);
    transition: all .3s ease-in;
    transform: scaleY(1.1);

}
