.header {
    text-align: end;
    background-color: #dad7d757;
    height: 8vh;
    border-radius: 10px;
  }
  
  .body {
    margin-top: 15px;
    /* box-shadow: -1px 4px 20px -6px rgba(0, 0, 0, 0.7); */
    background-color: #dad7d757;
    border-radius: 10px;
    height: 100%
  }

  .cardsAnalytics {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    font-family: monospace;
    padding: 40px 15px 0px 15px;
  }

  .startPageDiv {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    font-family: monospace;
    padding: 40px 15px 0px 15px;
  }

  .answerSheetDiv {
    /* display: flex; */
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    /* justify-content: flex-start; */
    font-family: monospace;
    padding: 40px 15px 0px 15px;
  }
  
  .cardsAnalytics > div {
    background-color: white;
    box-shadow: 0 8px 10px 0px rgba(16, 16, 17, 0.5);
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    width: 15%;
    /* margin: 0px 0px 30px 0px; */
    display: flex;
    align-items: right;
    flex-direction: column;
    padding: 15px;
  }

  .startPageDiv > div {
    background-color: white;
    box-shadow: 0 8px 10px 0px rgba(16, 16, 17, 0.5);
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    width: 15%;
    /* margin: 0px 0px 30px 0px; */
    display: flex;
    align-items: right;
    flex-direction: column;
    padding: 15px;
  }

  .answerSheetDiv > div {
    display: flex;
    align-items: right;
    flex-direction: column;
    padding: 15px;
  }

  .cardsAnalytics > .MuiButton-label {
    color: gray !important;
  }

  .startPageDiv > .MuiButton-label {
    color: gray !important;
  }

  .answerSheetDiv > .MuiButton-label {
    color: gray !important;
  }

  .slide > .MuiSlider-root.Mui-disabled {
    color: #52af77 !important;
  }

  .buttonPrimary {
    border: none;
    color: #fff;
    /* background-image: linear-gradient(30deg, #0400ff, #4ce3f7); */
    
    border-radius: 20px;
    background-size: 100% auto;
    font-family: inherit;
    font-size: 17px;
    padding: 0.6em 1.5em;
   }
   .buttonSecondary {
    border: none;
    color: #fff;
    /* background-image: linear-gradient(30deg, #662D8C, #ED1E79); */
    border-radius: 20px;
    background-size: 100% auto;
    font-family: inherit;
    font-size: 17px;
    padding: 0.6em 1.5em;
   }
   
   /* .buttonPrimary:active {
    transform: translate(1px ,0px);
    box-shadow: 0px 1px 0px rgb(124, 109, 189);
    padding-bottom: 3px;
  } */
   
   .buttonPrimary:hover {
    background-position: right center;
    background-size: 200% auto;
    -webkit-animation: pulse 2s infinite;
    animation: pulse512 1.5s infinite;
   }
   .buttonSecondary:hover {
    background-position: right center;
    background-size: 200% auto;
    -webkit-animation: pulse 2s infinite;
    animation: pulse513 1.5s infinite;
   }
   @keyframes pulse512 {
    0% {
     box-shadow: 0 0 0 0 #05bada66;
    }
   
    70% {
     box-shadow: 0 0 0 10px rgb(218 103 68 / 0%);
    }
   
    100% {
     box-shadow: 0 0 0 0 rgb(218 103 68 / 0%);
    }
   }

   @keyframes pulse513 {
    0% {
     box-shadow: 0 0 0 0 #e90a0a66;
    }
   
    70% {
     box-shadow: 0 0 0 10px rgba(216, 72, 67, 0);
    }
   
    100% {
     box-shadow: 0 0 0 0 rgb(218 103 68 / 0%);
    }
   }

   /* .inputdesign {
    border: 2px solid transparent;
    width: 15em;
    height: 2.5em;
    padding-left: 0.8em;
    outline: none;
    overflow: hidden;
    background-color: #F3F3F3;
    border-radius: 10px;
    transition: all 0.5s;
  } */
  
  .inputdesign:hover,
  .inputdesign:focus {
    border: 2px solid #4A9DEC;
    box-shadow: 0px 0px 0px 7px rgb(74, 157, 236, 20%);
    background-color: white;
  }
  