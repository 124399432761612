.attendhello {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: #FBF9F1;
  border: 20px;
  border-radius: 10px;
  padding: 50px; 
  margin:  auto;
  /* margin-left: 500px; */
  color: #0a0a0a;
  box-shadow: 1px 1px 10px 0px rgb(12, 12, 43);
  font-size: 20px;
  
}
.box {
  border-radius: 10px;
  margin-top: 1rem;
  width: 50%;
  height: 150px;
  padding: 10px 10px 10px 10px;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 10px 0px red;
}

.tex {
  padding: 1rem;
  border: 1px solid #E5E1DA;
  border-radius: 10px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 1rem;
  background-color: #e0e0e0;;
  color:rgba(0, 0, 0, 0.87);;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}

.but {
  padding: 0.5rem 5rem;
  /* background-color: #92C7CF; */
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin:auto

}

.but:hover {
  /* background-color: #aad9bc; */
  background-color:rgb(105, 63, 105);
  
}