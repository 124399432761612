* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.container {
    margin-top: 5rem;
}

.header {
    margin: 10px 0;
}

.emp-list-select {
    display: block;
    position: relative;
    margin: 30px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}

#emp-list {
    height: 65px;
}

.feedback-outer {
    display: flex;
    justify-content: center;
    padding: 20px 30p;
    height: 200px;
}

.feedback-inner {
    width: 100%;
    padding: 20px;
}

.emp-rating>div {
    width: 200px;
}

.emp-rating-outer {
    display: flex;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
}

#rate-text-div {
    margin-left: 20px;
}

#rate-text {
    font-size: 1.5rem;
}

.update-btn-div {
    position: relative;
    margin: 1rem auto;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
}

.display-rating {
    margin-top: 3rem;
}

.show-cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5rem;
}