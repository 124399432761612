.button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    gap: 15px;
    background-color: #007ACC;
    outline: 3px #007ACC solid;
    outline-offset: -3px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: 400ms;
  }
  
  .button .text {
    color: #007ACC;
    font-weight: 700;
    font-size: 1em;
    transition: 400ms;
  }
  
  .button svg path {
    transition: 400ms;
  }
  
  .button:hover {
    background-color: transparent;
  }
  
  .button:hover .text {
    color: #007ACC;
  }
  
  .button:hover svg path {
    fill: #007ACC;
  }