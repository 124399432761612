.userDetails {
  padding: 20px 0;
}


.user-item {
  width: 100%;
  padding: 5px 40px;
  margin: 20px auto;
  cursor: pointer;
  /* Change cursor to pointer on hover */
  transition: background-color 0.3s;
  /* Smooth transition for background color */
  border: 2px solid black;
  border-radius: 20px;
  background-color: rgb(14, 63, 108);
  color: white
}

.user-item:hover {
  background-color: black;
  /* Change background color on hover */
  color: white;
}

/* Add icon styles */
.user-item svg {
  margin-right: 10px;
  /* Add margin to the right of the icon */
}





.cards-container {
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  margin: 5px auto;
  /* Allow items to wrap to the next line */
  justify-content: space-between;
  /* Adjust the spacing between cards */
}

.card {
  width: calc(50% - 20px);
  /* Set card width (50% of container width minus margin) */
  margin-bottom: 20px;
  /*Adjust the spacing between rows */
}

.list-item {
  display: flex;
  align-items: center;
  padding-left: 5px;

}


.hover-scrollbar::-webkit-scrollbar {
  width: 6px;
  /* Width of the scrollbar */
}

.hover-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Color of the track */
}

.hover-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  /* Color of the thumb */
  border-radius: 3px;
  /* Roundness of the thumb */
}

.hover-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* Color of the thumb on hover */
}

* {
  box-sizing: border-box;
}

.leftContentHeader {
  width: 100%;
}

.contentHeader {
  width: 100%;
  padding: 0px 10px 40px 10px;
}

.homeHeading {
  font-size: 2rem;
  margin-left: 0;
  font-weight: 300;
  font-weight: 500;
  color: #162e70;
}

.mainPara {
  font-size: 20px;
  font-weight: 400;
  color: #162e70;
  margin-top: 15px;
}

.showmore {
  background-color: #EA4C89;
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  line-height: 20px;
  list-style: none;
  margin: 0;
  outline: none;
  padding: 10px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color 100ms;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  box-shadow: 0 8px 10px 0px rgba(16, 16, 17, 0.5);
}

.showmore:hover {
  color: black;
  border-color: currentColor;
  background-color: #F082AC;
  box-shadow: 0 8px 10px 0px rgba(16, 16, 17, 0.5);
}

.cardsAnalytics {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  font-family: monospace;
  flex-wrap: wrap;
}

.analytics-page {
  font-family: 'Arial', sans-serif;
  margin: 20px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.analytics-boxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.analytics-box {
  width: 100px;
  flex: 1;
  background-color: #ecf0f1;
  color: #333;
  padding: 20px;
  margin: 10px;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;
}

h2 {
  color: #2c3e50;
}

h4 {
  /* color: #2c3e50; */
  font-weight: bold;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin-bottom: 5px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

th,
td {
  /* border: 1px solid #bdc3c7; */
  padding: 8px;
  text-align: left;
}

th {
  background-color: #3498db;
  color: #ecf0f1;
}

@media screen and (max-width: 1200px) {
  .analytics-box {
    flex: 0 0 48%;
  }
}

@media screen and (max-width: 768px) {
  .analytics-box {
    flex: 0 0 100%;
  }
}

.eye-icon {
  cursor: pointer;
  margin-left: 5px;
  font-size: 1.8rem;
  font-weight: bold;
}

.eye-icon:hover {
  cursor: pointer;
}

.analytics-boxesForPersonalinfo {
  flex-wrap: wrap;
  justify-content: space-between;
}

.analytics-boxForPersonalInfo {
  display: flex;
  background-color: #ecf0f1;
  color: #333;
  padding: 20px;
  margin: 10px;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.left-content {
  margin-right: 20px;
  color: #C27BA0;
}

.left-content svg {
  width: 8.5rem;
  height: 6.5rem;
}

.right-content {
  flex-grow: 1;
}

.right-content span {
  font-weight: bold;
  font-size: 18px;
}

.right-content p {
  font-size: 14px;
  margin: 8px 0;
}

@media (max-width: 768px) {
  .right-content span {
    font-size: 16px;
  }

  .right-content p {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .analytics-boxForPersonalInfo {
    flex-direction: column;
  }

  .analytics-boxesForPersonalinfo {
    justify-content: flex-start;
  }

  .left-content svg {
    width: 4rem;
    height: 4rem;
  }
}

.custom-tooltip {
  position: relative;
  display: inline-block;
}

.custom-tooltip .tooltip-content {
  visibility: hidden;
  background-color: #333;
  color: #fff;
  font-weight: bolder;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.custom-tooltip:hover .tooltip-content {
  visibility: visible;
  opacity: 1;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
}

.mainHeading {
  font-weight: bold;
  color: #EA4C89;
  text-align: center;
  font-size: 2.5rem;
  padding-left: 1.5rem;
  width: 100%;
  color: rgb(103, 37, 103);
}

/* profile info section */

.user-sec {
  margin-top: 2rem;
  margin-left: 20px;
  padding-bottom: 10px;
}

.user-Section {
  background-color: hsl(192, 15%, 94%);
  box-shadow: 2px 5px 15px rgb(145, 137, 137);
  height: 70px;
  border-radius: 10px;
  margin-left: 10px;
}

.inner-cont {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  /* padding-bottom: 10px; */

}

.inner-cont>div {
  width: 200px;
  text-align: center;
  padding-top: 20px;
}

.inner-cont>div>button::after {
  content: "";
  height: 3px;
  background-color: #EA4C89;
  display: block;
  transition: all 0.3s ease;
}

.inner-cont button:focus::after {
  width: 150px;
}


.inner-cont button {
  border: 0;
  outline: 0;
  text-align: center;
  letter-spacing: 2px;
  font-size: 1.3rem;
  font-family: inherit;
  background-color: transparent;
  color: #000;
}

/* User Details section */
.ud-container {
  background-color: #ecf0f1;
  border-radius: 10px;
  box-shadow: 5px 5px 10px rgb(176, 172, 172);
  padding: 20px 25px;
}

.ud-left-outer {
  margin-left: 3rem;
}

.ud-left-inner>div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 25px 10px;
  width: 100%;
  cursor: pointer;
}

.ud-left-inner>div:hover {
  /* background-color: rgba(220, 217, 217, 0.651); */
  color: #EA4C89;
}

.ud-left-inner img {
  margin-bottom: 0;
  margin-right: 8px;
  width: 30px;
  height: 30px;
}

.ud-left-inner>div>h6 {
  font-weight: 400;
  padding-top: 10px;
  padding-left: 10px;
  font-size: 1.3rem;

}

.ud-left-inner>div>:hover {
  color: #EA4C89;
  fill: #EA4C89;
  transition: all .2s ease-in;
  scale: -2px;
}



.scroll-vertical {
  content: " ";
  height: 450px;
  width: 3px;
  background-color: rgb(193, 192, 192);
  display: absolute;
  margin-left: 7rem;
  padding-top: 1rem;
}

/* right section */
.ud-right {
  height: 100%;
  width: 100%;
  padding-top: 1.5rem;
}

.ud-right-inner {
  display: flex;
  justify-content: space-between;
}

/* .edit-btn{
      border: 1px solid #EA4C89;
      border-radius: 50%;
      fill: #EA4C89;
      width: 35px;
      height: 35px;
      position: absolute;        
      /* bottom: 10%; */
/* right:80px;
      padding:5px
  } */



.user-biodata {
  margin-left: 2.5rem;
}

.add-details>h3,
.cont-dtl>h3,
.dep-details>h3 {
  font-family: 'Times New Roman', Times, serif;
  font-size: 2rem;
  font-weight: 600;
}

.user-about>div>h6,
.user-bio>div>h6,
.user-dob>div>h6 {
  color: #ea4c89dc;
  letter-spacing: 1px;
}

@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');

.user-about>div>p,
.user-bio>div>p,
.user-dob>div>p {
  font-size: 1.3rem;
  font-weight: bold;
  font-family: 'Playfair Display', serif;
  letter-spacing: 3px;
  padding-left: 10px;
}

.add-details>h3,
.cont-dtl>h3,
.dep-details>h3 {
  font-family: 'Times New Roman', Times, serif;
  font-size: 2rem;
  font-weight: 600;

}

.add-head,
.cont-head,
.dep-head {
  margin-top: 3rem;
}

.add-head,
.cont-head,
.dep-head h6 {
  color: rgb(160, 157, 157);
  font-family: 'Times New Roman', Times, serif;
  letter-spacing: 2px;
}

.add-btn,
.cont-btn,
.dep-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  margin-left: 20px;
  width: 100px;
  height: 40px;
  font-size: 1.5rem;
  color: white;
  background-color: #EA4C89;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  box-shadow: 5px 3px 10px rgb(198, 132, 143);
  font-family: 'Times New Roman', Times, serif;
  transition: all .25s ease-in-out;
}

.add-btn:hover,
.cont-btn:hover,
.dep-btn:hover {
  height: 44px;
  width: 105px;
  transition: all .25s ease-in-out;
}

.add-img,
.cont-img,
.dep-img {

  width: 350px;
}

.rep-city-cont,
.user-curr-exp {
  margin-top: 2rem;
}

.rep-city>div>p,
.user-exp>div>p {
  color: #333;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  line-break: auto;
}

.rep-location>h5 {
  font-size: 1.5rem;
  width: 100%;
  font-weight: 700;
  margin-top: 0;
  color: #171515;
  text-shadow: 2px 2px 10px grey;
}

.loc-btn {
  height: 20px;
  width: 60px;
  font-size: .9rem;
  padding: 0;
  margin-left: 0;
  background-color: grey;
  box-shadow: none;
  transition: all .25s ease-in-out;
}

.loc-btn:hover {
  height: 22px;
  width: 65px;
  background-color: #EA4C89;
  box-shadow: 5px 3px 10px rgb(198, 132, 143);
  transition: all .25s ease-in-out;
}