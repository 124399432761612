.cardsAppointment {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.cardsAppointment>div {
  background: rgb(14, 63, 108);

  box-shadow: 0 8px 10px 0px rgba(16, 16, 17, 0.5);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  width: 20%;
  margin: 20px 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-around;

  padding: 15px;
}

.cardsAppointment>div>h3 {
  text-align: center;
  font-size: 0.7vw;
  color: white;
}

.cardsAppointment>div>h2 {
  text-align: center;
  font-size: 2vw;
  color: white;
}

@media screen and (max-width: 720px) {
  .pccDashContainer {
    width: 100%;
    margin: auto;
    height: calc(100vh - 120px);
    overflow-y: scroll;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px;
  }

  .cardsAppointment>div {
    width: 100%;
   
  }

  .cardsAppointment>div>h3 {
    text-align: center;
    font-size: 2vw;
    color: white;
  }

  .cardsAppointment>div>h2 {
    text-align: center;
    font-size: 5vw;
    color: white;
  }

  .pccDashContainer>div>h3 {
    font-size: 5vw;
    margin: 30px 0px 20px 0px;
    color: rgba(66, 58, 58, 0.87);
  }
}