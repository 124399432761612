.hello {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background-color: #FBF9F1;
    border: 20px;
    border-radius: 10px;
    padding: 50px; 
    margin:  auto;
    color: #0a0a0a;
    box-shadow: 1px 1px 10px 0px rgb(12, 12, 43);
    font-size: 20px;
  
    
  }
  .box {
    border-radius: 10px;
    margin-top: 1rem;
    width: 50%;
    height: 150px;
    padding: 10px 10px 10px 10px;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    
    box-shadow: 1px 1px 10px 0px red;
  }
  
    
  
  .medical{
  /* width: 300px; */
  padding: 1rem;
  border: 1px solid #E5E1DA;
  border-radius: 10px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 1rem;
  background-color: #E5E1DA;
  color:#0a0a0a;
  }

  .button {
    color: rgb(14, 63, 108);
  }