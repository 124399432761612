* {
  box-sizing: border-box;
  --theme: #162e70;
  --lightTheme: #496cce;

}

.leftContentHeader {
  width: auto;
  margin: 10px 0;
}

.leftContentHeader h2 {
  font-size: 2.5rem;
  color: #2c3e50;
}

.contentHeader {
  width: 100%;
  padding: 0px 10px 40px 10px;
}

.homeHeading {
  font-size: 2rem;
  margin-left: 0;
  font-weight: 300;
  font-weight: 500;
  color: #162e70;
}

.mainPara {
  font-size: 20px;
  font-weight: 400;
  color: #162e70;
  margin-top: 15px;
}

.card {
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: 280px;
  width: auto;
  margin: 5px;
}

.card>ul>li {
  word-wrap: break-word;
}

.card-head {
  font-size: 1.2rem;
}

.profile-card {
  height: 560px;
}

.card-main {
  display: flex;

  align-items: center;
  margin-left: 50px;
}

.cards-container {
  width: auto;
}

.card {
  margin: 10px !important;
}




.showmore {
  background-color: var(--theme);
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  line-height: 20px;
  list-style: none;
  margin: 0;
  outline: none;
  padding: 10px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color 100ms;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  box-shadow: 0 8px 10px 0px rgba(16, 16, 17, 0.5);
}

.showmore:hover {
  color: black;
  border-color: currentColor;
  background-color: #F082AC;
  box-shadow: 0 8px 10px 0px rgba(16, 16, 17, 0.5);
}

.cardsAnalytics {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  font-family: monospace;
  flex-wrap: wrap;
}

.analytics-page {
  font-family: 'Arial', sans-serif;
  margin: 20px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.analytics-boxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.analytics-box {
  width: 100px;
  flex: 1;
  background-color: #ecf0f1;
  color: #333;
  padding: 20px;
  margin: 10px;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;
}

h4 {
  /* color: #2c3e50; */
  font-weight: bold;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin-bottom: 5px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

th,
td {
  /* border: 1px solid #bdc3c7; */
  padding: 8px;
  text-align: left;
}

th {
  background-color: #3498db;
  color: #ecf0f1;
}

@media screen and (max-width: 1200px) {
  .analytics-box {
    flex: 0 0 48%;
  }
}

@media screen and (max-width: 768px) {
  .analytics-box {
    flex: 0 0 100%;
  }
}

.eye-icon {
  cursor: pointer;
  margin-left: 5px;
  font-size: 1.8rem;
  font-weight: bold;
}

.eye-icon:hover {
  cursor: pointer;
}

.analytics-boxesForPersonalinfo {
  flex-wrap: wrap;
  justify-content: space-between;
}

.analytics-boxForPersonalInfo {
  display: flex;
  background-color: #ecf0f1;
  color: #333;
  padding: 20px;
  margin: 10px;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.left-content {
  margin-right: 20px;
  /* color: #C27BA0; */
  color: var(--theme);
}

.left-content svg {
  width: 8.5rem;
  height: 6.5rem;
}

.right-content {
  flex-grow: 1;
}

.right-content span {
  font-weight: bold;
  font-size: 18px;
}

.right-content p {
  font-size: 14px;
  margin: 8px 0;
}

@media (max-width: 768px) {
  .right-content span {
    font-size: 16px;
  }

  .right-content p {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .analytics-boxForPersonalInfo {
    flex-direction: column;
  }

  .analytics-boxesForPersonalinfo {
    justify-content: flex-start;
  }

  .left-content svg {
    width: 4rem;
    height: 4rem;
  }
}

.custom-tooltip {
  position: relative;
  display: inline-block;
}

.custom-tooltip .tooltip-content {
  visibility: hidden;
  background-color: #333;
  color: #fff;
  font-weight: bolder;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.custom-tooltip:hover .tooltip-content {
  visibility: visible;
  opacity: 1;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
}

.mainHeading {
  font-weight: bold;
  color: var(--theme);
  font-size: 2.5rem;
  padding-left: 1.5rem;
  width: 100%;
}

/* profile info section */

.user-sec {
  margin-top: 2rem;
  margin-left: 20px !important;
  margin-right: 15px !important;
  padding-bottom: 10px;
  /* width: 94%; */
}

.user-Section {
  background-color: hsl(192, 15%, 94%);
  box-shadow: 2px 5px 15px rgb(145, 137, 137);
  height: 70px;
  border-radius: 10px;
  /* margin-left: 10px; */
}

.inner-cont {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  padding-left: 10px;
}

.inner-cont>div {
  width: 200px;
  text-align: center;
  padding-top: 20px;
}

.inner-cont>div>button::after {
  content: "";
  height: 3px;
  background-color: var(--theme);
  display: block;
  width: 0;
  transition: all .1s ease;
}

.inner-cont button:hover::after {
  width: 150px;
  transition: all .2s ease;
}


.inner-cont button {
  border: 0;
  outline: 0;
  text-align: center;
  letter-spacing: 2px;
  font-size: 1.3rem;
  font-family: inherit;
  background-color: transparent;
  color: #000;
}

/* User Details section */
.ud-container {
  display: flex;
  width: 94%;
  height: 550px;
  margin: 3rem;
  background-color: #ecf0f1;
  border-radius: 10px;
  box-shadow: 5px 5px 10px rgb(176, 172, 172);
  padding: 20px 25px;
  overflow-y: scroll;
}

.ud-left-outer {
  margin-left: 3rem;
}

.ud-left-inner>div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 25px 10px;
  width: 100%;
  cursor: pointer;
}

.ud-left-inner>div:hover {
  /* background-color: rgba(220, 217, 217, 0.651); */
  color: var(--lightTheme);
}

#ud-left-icon {
  margin-bottom: 0;
  margin-right: 8px;
  width: 30px;
  height: 30px;
  fill: var(--theme);
}

.ud-left-inner>div>h6 {
  font-weight: 700;
  padding-top: 10px;
  padding-left: 10px;
  font-size: 1.4rem;
  color: var(--theme);
  font-weight: 700;
}

.ud-left-inner>div>:hover {
  color: rgb(93, 136, 231);
  fill: var(--lightTheme);
  transition: all .2s ease-in;
  text-decoration: underline;
  text-shadow: 1px 1px 5px rgb(160, 85, 85);
}

.middle {
  padding-top: 2rem;
  /* height: 100%; */
}

.scroll-vertical {
  content: " ";
  height: 410px;
  width: 3px;
  background-color: rgb(193, 192, 192);
  margin-left: 3rem;
  margin-right: 2rem;
}

/* right section */
.ud-right {
  height: 100%;
  width: 100%;
  padding-top: 1rem;
}

.ud-right-cont {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-right: 2rem;
}



.ud-right-inner {
  display: flex;
  justify-content: space-between;
  width: auto;
}

@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');

/* Edit button for form */
.form-container {
  max-width: inherit;
}


.aboutSec>div>h6 {
  margin-bottom: 10px;
  font-size: 1.05rem;
  color: var(--theme);
  font-weight: 600;
  font-family: Georgia, 'Times New Roman', Times, serif;
  text-decoration: dashed;
}

.dynamic-form-values,
.static-form-values {
  margin-bottom: 30px;
}

.dynamic-form-values h6,
.static-form-values>h6 {
  margin-bottom: 10px;
  font-size: 1.05rem;
  color: var(--theme);
  font-weight: 600;
  font-family: Georgia, 'Times New Roman', Times, serif;
  text-decoration: dashed;
}

.aboutSec>div>p {
  width: 500px;
  height: 40px;
  padding: 8px;
  border: 1px solid #fff;
  border-radius: 10px;
  box-sizing: border-box;
  font-size: 1rem;
  letter-spacing: 2px;
  text-align: center;
  border-radius: 20px;
  box-shadow: inset 0px 1px 10px rgba(149, 146, 146);

}

.aboutSec>div {
  margin: 20px;
}

.aboutSec>div>input {
  width: 500px;
  padding: 8px;
  border: 0;
  outline: 0;
  background-color: transparent;
  box-sizing: border-box;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 1px;
  border-radius: 10px;
  box-shadow: 1px 1px 10px rgba(131, 154, 195, 0.82);
  padding-left: 40px;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.aboutSec>div>input:focus {
  border: 1px solid blue;
  color: var(--lightTheme);
  font-size: 1.2rem;
  font-weight: 500;
  transition: all .2s ease-in-out;
}

.aboutSec>div>input::placeholder {
  color: #848383;
  font-size: .9rem;
  font-weight: 400;
}

.dynamiv-form-values input:focus {
  outline: none;
  border-color: #007bff;
}

.save-btn button {
  background-color: var(--theme);
  width: 100px;
  color: #FFFFFF;
  border-radius: 10px;
  transition: all .2s ease-in-out;
  margin: 10px;
  padding: 5px;

}

.save-btn button:hover {
  background-color: blue;
}

.edit-btn {
  border: 2px solid #1b1618;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  padding: 5px;
  position: sticky;
  transition: all .5s ease-in-out;
}

.edit-btn:hover {
  border: 2px solid rgb(61, 15, 224);
  color: var(--theme);
  transition: all .5s ease-in-out;
  transform: rotate3d(0, 0, 1, 360deg);
  ;
}

.user-biodata {
  margin-left: 1.5rem;
}

.work-profile>div>h6,
.user-bio>div>h6,
.user-dob>div>h6 {
  margin-bottom: 10px;
  font-size: 1.05rem;
  color: var(--theme);
  font-weight: 600;
  font-family: Georgia, 'Times New Roman', Times, serif;
  text-decoration: dashed;
  margin-bottom: 1rem;
}



.work-profile>div>p,
.user-bio>div>p,
.user-dob>div>p {
  font-size: 1.3rem;
  font-weight: bold;
  font-family: 'Playfair Display', serif;
  letter-spacing: 3px;
  padding-left: 10px;
}

.rep-location>h3,
.add-details>h3,
.cont-dtl>h3,
.dep-details>h3 {
  font-family: 'Times New Roman', Times, serif;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 2rem;
  color: var(--theme);
}



.add-head,
.cont-head,
.dep-head h5 {
  margin-bottom: 2rem;
  color: var(--theme);
  font-family: 'Times New Roman', Times, serif;
  letter-spacing: 2px;
}

.cont-div,
.add-div,
.dep-div {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  /* margin-top: 2rem; */
  width: 100%;
}

.add-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  margin-left: 20px;
  width: 100px;
  height: 40px;
  font-size: 1.5rem;
  color: white;
  background-color: var(--theme);
  cursor: pointer;
  border: none;
  border-radius: 5px;
  box-shadow: 5px 3px 10px rgb(99, 121, 193);
  font-family: 'Times New Roman', Times, serif;
  transition: all .25s ease-in-out;
}

.add-btn:hover,
.cont-btn:hover,
.dep-btn:hover {
  height: 44px;
  width: 105px;
  transition: all .25s ease-in-out;
}

.add-img,
.cont-img,
.dep-img {
  align-self: center;
  min-width: 200px;
  max-width: 400px;
  margin-left: 100px;
  padding-right: 1rem;
}

.dep-details {
  width: 250px;
}

.rep-img {
  margin-left: 10px;
  width: 350px;

}

.cont-img {
  width: 310px;
}

.rep-city-cont,
.user-curr-exp {
  margin-top: 2rem;
  width: 250px;

}

.rep-city>div>h6,
.user-exp>div>h6 {
  color: #333;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  line-break: auto;
  font-family: 'Times New Roman', Times, serif;
  margin-bottom: 1rem;
}

.loc-btn {
  height: 20px;
  width: 60px;
  font-size: .9rem;
  padding: 0;
  margin-left: 0;
  background-color: grey;
  box-shadow: none;
  transition: all .25s ease-in-out;
}

.loc-btn:hover {
  height: 22px;
  width: 65px;
  background-color: var(--theme);
  box-shadow: 5px 3px 10px var(--lightTheme);
  transition: all .25s ease-in-out;
}


.input-type-file {
  border: 1px dashed var(--theme);
  padding: 10px;
  margin: 2rem;
}

.contEditSec>div {
  margin: 10px;
}

.contEditSec>div>h5,
.editDependents>div>h5 {
  font-size: 1rem;
  color: var(--theme);
  display: flex;
  margin: 5px 0;
}


/* For small screens */

@media only screen and (min-width: 250px) and (max-width:500px) {

  .card-main {
    display: block;
    margin-left: 0 !important;
  }

  .cards-container {
    display: block;
    width: auto !important;
    margin-left: auto;
  }

  .card-comp {
    margin: 0 !important;
  }

  .user-card {
    width: auto !important;
  }

  .left-card-cont {
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .userDetails {
    padding: 0 !important;
  }

  .user-item {
    width: auto !important;
  }

  .profile-card {
    height: 100%;
  }

  .card {
    flex: 1 1 100%;
    margin: 5px 0 !important;
  }

  .inner-cont button {
    font-size: .9rem !important;
  }

  .scroll-vertical {
    margin-left: 20px !important;
  }


  .user-biodata {
    margin-left: 0 !important;
  }

  .user-sec {
    margin-left: 0 !important;
    width: auto !important;
  }

  .user-Section {
    margin-left: 0 !important;

  }

  .ud-left-outer {
    margin-left: 10px !important;

  }

  .user-Section button {
    font-size: .6rem;
  }

  .ud-container {
    padding: 10px 5px !important;
    width: 90% !important;
    margin: 1rem !important;
  }

  .aboutSec>div>p {
    width: 200px !important;
    letter-spacing: 0;
  }

  .cont-div,
  .add-div,
  .dep-div {
    display: inline-block !important;
    margin-top: 2rem;
    width: auto !important;
  }

  .add-img,
  .dep-img,
  .cont-img {
    width: 210px !important;
    height: 200px !important;
    margin-left: 10px;
    padding-right: 10px;
  }

  .doc-img {
    display: block;
    padding-top: 0 !important;
    width: 200px;
    height: 160px;
  }

  .exp-img {
    display: none;
  }
}


@media only screen and (min-width: 500px) and (max-width:700px) {

  .card-main {
    display: block;
    margin-left: 0 !important;
  }

  .cards-container {
    display: block;
    width: auto !important;
    margin-left: auto;
  }

  .user-card {
    width: auto !important;
  }

  .profile-card {
    justify-content: space-evenly;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  .card {
    flex: 1 1 100%;
    margin: 10px !important;
  }

  .card svg {
    width: 15px !important;
    height: 15px !important
  }

  .inner-cont button {
    font-size: 1.1rem !important;
  }

  .scroll-vertical {
    margin-left: 20px !important;
  }

  .user-biodata {
    margin-left: 0 !important;
  }

  .user-Section {
    margin-left: 0 !important;
  }

  .ud-container {
    padding: 10px 5px !important;
    width: 98% !important;
    margin: 3rem 1rem;
  }

  .ud-left-outer {
    margin-left: 10px !important;
  }

  .aboutSec>div>p {
    width: 220px !important;
  }
  
  .cont-div,
  .add-div,
  .dep-div {
    display: inline-block !important;
    margin-top: 2rem;
    width: auto !important;
  }

  .add-img,
  .dep-img,
  .cont-img {
    width: 260px !important;
    height: 240px !important;
    margin-left: 10px;
    padding-right: 10px;
  }

  /* .add-exp {
    display: none;
  } */

  .doc-img,
  .exp-img {
    display: block;
    padding-top: 0 !important;
    width: 200px;
    height: 160px;
  }

  .add-exp {
    display: none !important;
  }
}

/* For medium screens */
@media only screen and (min-width: 700px) and (max-width: 1000px) {

  .cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: 20px;
  }

  .user-card {
    width: 220px !important;
  }

  .card {
    flex: 1 1 40%;
    margin: 10px !important;
  }

  .card svg {
    width: 15px !important;
    height: 15px !important
  }

  .ud-left-outer {

    margin-left: 0 !important;
  }

  .scroll-vertical {
    margin-left: 20px !important;
  }

  .user-biodata {
    margin-left: 0 !important;
  }


}

/* For large screens */
@media only screen and (min-width: 1000px) {
  .cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .card {
    flex: 1 1 40%;
    margin: 10px 0;
  }


}


.centerItem{
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.ExpTitle{
  color: #413c9f !important;
  font-size: xx-large !important;
  font-weight: 700;
}
.companyName{
  font-size: 25px !important;
  font-weight: 700 !important;
}
.validationError{
  color: #f44336;
  font-size:0.75rem;
  font-weight:400;
  line-height:1.66;
  margin-top:3px;
  margin-left:14px;
  margin-right:14px;
  letter-spacing:0.03333em;
}

.uploadField{
  width: 100%;
  margin: 0;
  padding: 7px !important;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 5px;
  height: 40px !important;
  cursor: pointer;
}
       
.uploadInput input{
  color: black !important;
  padding: 8.5px 14px !important;
}
       
.uploadInput label{
  transform: translate(14px, -6px) scale(0.75) !important;
  padding: 0 5px;
  background: white !important;
}

.editButton{
  border: 1px solid rgba(0, 0, 0, 0.23);
  background: none;
  padding: 7px;
  border-radius: 5px;
}

.disableDate :disabled{
  background-color:  #EBEBE4;
}
.disableDate input:disabled{
  color: gray !important;
}

.showCredential{
  margin-top: 12px;
  padding: 10px;
  border-radius: 20px;
  font-size: 15px;
  font-weight: 500;
}

.medicalUploadField{
  width: 100%;
  margin: 0;
  padding: 7px !important;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 5px;
  height: 44px !important;
  cursor: pointer;
}