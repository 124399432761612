.input
    .MuiSelect-root
        padding: 18.5px 14px !important
    input
        color: black !important
        padding: 18.5px 14px !important
    label
        transform: translate(14px, -6px) scale(0.75) !important
        padding: 0 5px
        background: white !important

.MuiOutlinedInput-input
    padding: none!important
    
.MuiOutlinedInput-input
    padding: 3px 11px!important


    .inline
        display: flex!important
.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
    padding: 0!important
