*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.taxHeading{
    width: 100%;
    text-align: center;
    color: rgb(14, 63, 108);
    text-shadow: 5px 5px 15px grey;
    font-size: 3rem;
}