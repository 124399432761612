.cardsAnalytics {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    font-family: monospace;
  }
  
  .cardsAnalytics > div {
    box-shadow: 0 8px 10px 0px rgba(16, 16, 17, 0.5);
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);
    border-radius: 4px;
    background-color: #d0e0e2;
    border: 4px solid rgba(255, 255, 255, 0.18);
    width: 22%;
    display: flex;
    align-items: right;
    flex-direction: column;
    padding: 15px;
  }
  
  .card-data {
    display: block;
    margin-bottom: 5px; 
  }
  
  .right-align {
    float: right;
  }
  
  .cardsAnalytics > div > h3 {
    text-align: center;
    font-size: 0.7vw;
    color: white;
  }
  
  .cardsAnalytics > div > h2 {
    text-align: center;
    font-size: 2vw;
    color: white;
  }
  .attendance-regularize{
    border: 1px solid blue;
  }
  .leave-apply-btn{
    background-color: #EA4C89;
    border-radius: 8px;
    border-style: none;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    height: 40px;
    line-height: 20px;
    list-style: none;
    margin: 0;
    outline: none;
    padding: 10px 16px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: color 100ms;
    vertical-align: baseline;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    box-shadow: 0 8px 10px 0px rgba(16, 16, 17, 0.5);
  }
  .leave-apply-btn:hover {
    color: black;
    border-color: currentColor;
    background-color: #F082AC;
    box-shadow: 0 8px 10px 0px rgba(16, 16, 17, 0.5);
}

form {
  display: flex;
  flex-direction: column;
  align-items: right;
  margin-top: 50px;
}

label {
  margin-bottom: 10px;
}

/* input[type="date"],
input[type="time"],
input[type="text"] {
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 300px;
} */

/* button {
  padding: 10px 20px;
  border-radius: 2px;
  border: none;
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
  margin-top: 20px;

} */



.button-style {
  margin: 20px;
  padding: 5px 35px;
  border: none;
  border-radius: 5px;
  /* background-color: blue; */
  background-color:rgb(103, 37, 103);
  color: white;
  font-size: 16px;
  cursor: pointer;
  
}
.button-style:hover {
  
  background-color:rgb(105, 63, 105); 
}



.button1 {
 
  /* background-color: #4CAF50;  */
  border: none;
  background-color: #ffffff; 
  /* color: white; */
  color: rgb(144, 155, 204);
  text-align: center;
  display: inline-block;
  font-size: 20px;
  margin: 4px 2px;
  
  cursor: pointer;
  border-radius: 5px;
  border: 2px solid rgb(144, 155, 204);
  padding: 10px 24px;
  border-radius: 10px;
  width: 100%; /* Make the buttons full-width */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); 
}

@media screen and (min-width: 600px) {
  .button1 {
    width: 30%; /* Change the width of the buttons for larger screens */
  }
}
.button1:hover {
  background-color: #c7dfc7;
  
  

}
.use1{
  padding:auto;
  display: flex;
  border: none;
  background-color: #ffffff; 
  /* color: white; */
  color: rgb(144, 155, 204);
  text-align: center;
  display: inline-block;
  font-size: 20px;
  margin: 30px 2px;
  
  cursor: pointer;
  border-radius: 5px;
  
  padding: 10px 34px;
  border-radius: 10px;
  width: 100%; /* Make the buttons full-width */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); 
}

.use3{
  display: flex;
  padding:auto;
  border: none;
  background-color: #ffffff; 
  /* color: white; */
  color: rgb(144, 155, 204);
  text-align: center;
  display: inline-block;
  font-size: 20px;
  margin: 4px 2px;
  
  cursor: pointer;
  
  width: 100%; /* Make the buttons full-width */
 
}




