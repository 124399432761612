body
  margin: 0
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen'
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

code
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace

svg
  cursor: pointer
  
.MuiAutocomplete-inputRoot
  padding: 0px !important

.filter-header
  width: 95%
  margin: 0 auto
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
    padding: 0

.input
    .MuiSelect-root
        padding: 18.5px 14px !important
    input
        color: black !important
        padding: 18.5px 14px !important

