
  .sidebar {
     background-color: white;  
    color: black;
    padding: 20px;
    width: 20%;
    box-sizing: border-box;
  }
  
  .right-content {
    flex: 1;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .content-item {
    cursor: pointer;
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .content-item:hover {
    background-color: #555;
  }
  
  .content-details {
    display: none;
  }
  
  .content-details.active {
    display: block;
  }
  