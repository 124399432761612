.login-container
     
    height: 100vh
    background-size: 100%
    width: 100%
    background-repeat: no-repeat
    justify-content: center
    display: flex
    align-items: center
    background:#0e3f6c


.mainHeading 
    font-size: 2rem
    margin-left: 5rem
    width: 100%
    color: #010101
    font-weight: 300
    font-weight: 500
    color: #162e70
    
    

.login-inner-cont 
    display: flex
    align-items: center